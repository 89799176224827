/**
 * MUI Components whose styles are override as per theme
 * @param {JsonObject} theme Plain Json Object
 */
import { CustomTypography } from '../types';
import ClearIcon from '@mui/icons-material/Clear';
import { alpha } from '@mui/material';

export default function componentStyleOverrides(theme: CustomTypography) {
    const bgColor = theme?.customization?.navType === 'dark' ? theme.colors?.darkBackground : theme.colors?.paper;
    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    textTransform: 'capitalize' as const,
                    borderRadius: '4px',

                    '&.Cb-Table-Btn': {
                        padding: '4px 8px',
                        fontSize: '12px',
                        lineHeight: '16px',
                        minWidth: '54px',

                        '& .MuiButton-startIcon': {
                            ml: 0,
                            mr: '4px',
                            '& > *': { fontSize: '12px' }
                        },

                        '& .MuiButton-endIcon': {
                            mr: 0,
                            ml: '4px',
                            '& > *': { fontSize: '12px' }
                        },

                        '& .icon.icon-tabler': {
                            width: '1em',
                            height: '1em'
                        }
                    }
                },

                textSizeSmall: {
                    '&.forcedBg': {
                        paddingLeft: '10px',
                        paddingRight: '10px'
                    }
                },

                textSizeMedium: {
                    '&.forcedBg': {
                        paddingLeft: '16px',
                        paddingRight: '16px'
                    }
                },

                textSizeLarge: {
                    '&.forcedBg': {
                        paddingLeft: '22px',
                        paddingRight: '22px'
                    }
                },

                textPrimary: {
                    '&:hover, &.forcedBg': {
                        '&:not(.Mui-disabled)': {
                            backgroundColor: theme.colors?.primaryLight
                        }
                    }
                },

                textError: {
                    color: theme.colors?.errorDark,
                    '&:hover, &.forcedBg': {
                        '&:not(.Mui-disabled)': {
                            backgroundColor: theme.colors?.errorLight
                        }
                    }
                }
            },
            defaultProps: {
                disableElevation: true
            }
        },
        MuiPaper: {
            defaultProps: {
                elevation: 0
            },
            styleOverrides: {
                root: {
                    backgroundImage: 'none'
                },
                rounded: {
                    borderRadius: `${theme?.customization?.borderRadius}px`
                }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    color: theme.colors?.textDark,
                    padding: '24px'
                },
                title: {
                    fontSize: '1.125rem'
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '24px'
                }
            }
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: '24px'
                }
            }
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    alignItems: 'center'
                },
                outlined: {
                    border: '1px dashed'
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    color: theme.darkTextPrimary,
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    '&.Mui-selected': {
                        color: theme.menuSelected,
                        backgroundColor: theme.menuSelectedBack,
                        '&:hover': {
                            backgroundColor: theme.menuSelectedBack
                        },
                        '& .MuiListItemIcon-root': {
                            color: theme.menuSelected
                        }
                    },
                    '&:hover': {
                        backgroundColor: theme.menuSelectedBack,
                        color: theme.menuSelected,
                        '& .MuiListItemIcon-root': {
                            color: theme.menuSelected
                        }
                    }
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: theme.darkTextPrimary,
                    minWidth: '36px'
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    color: theme.textDark
                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '& .MuiInputLabel-shrink': {
                        fontSize: '12px',
                        fontWeight: '400',
                        top: 0
                    }
                }
            }
        },
        MuiTextField: {
            defaultProps: {
                variant: 'filled' as const
            },
            styleOverrides: {
                root: {
                    '& .MuiInputLabel-shrink': {
                        fontSize: '12px',
                        fontWeight: '400',
                        top: 0
                    },

                    '&.outline-url-input': {
                        '& .MuiOutlinedInput-input': {
                            color: theme.colors?.newDarkBlue,

                            '&.Mui-disabled': {
                                WebkitTextFillColor: theme.colors?.grey300,
                                backgroundColor: theme.colors?.grey200
                            }
                        },

                        '& .MuiOutlinedInput-root:not(.Mui-error):not(.Mui-focused):not(.Mui-disabled) .MuiOutlinedInput-notchedOutline': {
                            borderColor: theme.colors?.grey800
                        },

                        '& .MuiOutlinedInput-root.Mui-disabled': {
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.colors?.grey300
                            }
                        }
                    },

                    '&.monospaced-textarea': {
                        '& .MuiOutlinedInput-input': {
                            fontFamily: '"Roboto Mono", monospace',
                            backgroundColor: theme.colors?.grey200
                        },

                        '& .MuiOutlinedInput-root:not(.Mui-error):not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
                            borderColor: theme.colors?.grey300
                        }
                    }
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    color: theme.textDark,
                    '&::placeholder': {
                        color: theme.darkTextSecondary,
                        fontSize: '0.875rem'
                    },
                    '& .MuiInputLabel-shrink': {
                        fontSize: '12px',
                        fontWeight: '400',
                        top: 0
                    }
                }
            }
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    '&.MuiInputBase-colorPrimary': {
                        '&.Mui-focused:not(.Mui-error)': {
                            backgroundColor: theme.colors?.primaryLight
                        },
                        '&.Mui-error': {
                            backgroundColor: theme.colors?.errorLight
                        }
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: theme?.customization?.outlinedFilled ? bgColor : 'transparent',
                    borderRadius: `${theme?.customization?.borderRadius}px`,
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.colors?.newGray400
                    },
                    '&:hover $notchedOutline': {
                        borderColor: theme.colors?.newGray400
                    },
                    '&.MuiInputBase-multiline': {
                        padding: 1
                    },

                    '&.Mui-focused': {
                        color: theme.colors?.primaryMain,
                        borderColor: theme.colors?.primaryMain
                    },

                    '&.Mui-error': {
                        fontWeight: 300,
                        backgroundColor: theme.colors?.errorLight,
                        color: `${theme.colors?.errorMain} !important`
                    },

                    '& .MuiInputLabel-shrink': {
                        fontSize: '12px',
                        fontWeight: '400',
                        color: theme.colors?.newBlack,
                        top: 0
                    },

                    '&.input-blue': {
                        backgroundColor: theme.colors?.grey50,
                        fontSize: '1rem',

                        '& .MuiOutlinedInput-input::placeholder': {
                            opacity: 1,
                            color: theme.colors?.newDarkBlue,
                            fontSize: '1rem'
                        },

                        '& .MuiOutlinedInput-notchedOutline': {
                            borderWidth: '1px',
                            borderColor: theme.colors?.newDarkBlue
                        }
                    }
                },
                input: {
                    // fontWeight: 500,
                    background: theme?.customization?.outlinedFilled ? bgColor : 'transparent',
                    padding: '12px 14px',
                    borderRadius: `${theme?.customization?.borderRadius}px`,
                    '&.MuiInputBase-inputSizeSmall': {
                        padding: '10px 14px',
                        '&.MuiInputBase-inputAdornedStart': {
                            paddingLeft: 0
                        }
                    }
                },
                inputAdornedStart: {
                    paddingLeft: 4
                },
                notchedOutline: {
                    borderRadius: `${theme?.customization?.borderRadius}px`
                }
            }
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        color: theme?.customization?.navType === 'dark' ? theme.colors?.darkTextPrimary! + 50 : theme.colors?.grey300
                    }
                },
                mark: {
                    backgroundColor: theme.paper,
                    width: '4px'
                },
                valueLabel: {
                    color: theme?.customization?.navType === 'dark' ? theme?.colors?.primaryMain : theme?.colors?.primaryLight
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '& .MuiAutocomplete-tag': {
                        background:
                            theme?.customization?.navType === 'dark' ? theme.colors?.darkTextPrimary! + 20 : theme.colors?.secondaryLight,
                        borderRadius: 4,
                        color: theme.textDark
                    },
                    '& .MuiOutlinedInput-root': {
                        paddingTop: '4px',
                        paddingBottom: '4px'
                    },
                    '& .MuiButtonBase-root': { color: 'black' },
                    '& .MuiInputLabel-shrink': {
                        fontSize: '12px',
                        fontWeight: '400',
                        top: 0
                    },
                    label: {
                        top: '-3px',
                        fontSize: '14px',
                        fontWeight: 300
                    }
                },
                popper: {
                    '&[data-type=search-autocomplete-bottom]': {
                        position: 'fixed !important',
                        bottom: '110px !important',
                        width: '100vw !important',
                        transform: 'none !important',
                        maxHeight: 'calc(100vh - 110px - 8px)'
                    },
                    borderRadius: `${theme?.customization?.borderRadius}px`,
                    boxShadow: '0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)'
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: theme.divider,
                    opacity: theme?.customization?.navType === 'dark' ? 0.2 : 1
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    '&:focus': {
                        backgroundColor: 'transparent'
                    }
                }
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    color: theme?.customization?.navType === 'dark' ? theme.colors?.darkLevel1 : theme.colors?.primaryDark,
                    background: theme?.customization?.navType === 'dark' ? theme.darkTextPrimary : theme.colors?.primary200
                }
            }
        },
        MuiChip: {
            defaultProps: {
                deleteIcon: <ClearIcon />
            },
            styleOverrides: {
                root: {
                    '&.MuiChip-deletable .MuiChip-deleteIcon': {
                        color: 'inherit'
                    }
                }
            }
        },
        MuiTimelineContent: {
            styleOverrides: {
                root: {
                    color: theme.textDark,
                    fontSize: '16px'
                }
            }
        },
        MuiTreeItem: {
            styleOverrides: {
                label: {
                    marginTop: 14,
                    marginBottom: 14
                }
            }
        },
        MuiTimelineDot: {
            styleOverrides: {
                root: {
                    boxShadow: 'none'
                }
            }
        },
        MuiInternalDateTimePickerTabs: {
            styleOverrides: {
                tabs: {
                    backgroundColor: theme?.customization?.navType === 'dark' ? theme.colors?.darkPaper : theme.colors?.primaryLight,
                    '& .MuiTabs-flexContainer': {
                        borderColor:
                            theme?.customization?.navType === 'dark' ? theme?.colors?.darkTextPrimary! + 20 : theme.colors?.primary200
                    },
                    '& .MuiTab-root': {
                        color: theme?.customization?.navType === 'dark' ? theme.colors?.darkTextSecondary : theme.colors?.grey900
                    },
                    '& .MuiTabs-indicator': {
                        backgroundColor: theme.colors?.primaryDark
                    },
                    '& .Mui-selected': {
                        color: theme.colors?.primaryDark
                    }
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                scroller: {
                    borderBottom: '1px solid',
                    borderColor: alpha(String(theme.colors?.newDarkBlue), 0.6)
                },
                flexContainer: {
                    border: 'none !important',

                    '&:has(.Mui-selected.Mui-error) + .MuiTabs-indicator': {
                        backgroundColor: theme.colors?.errorMain
                    }
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    minHeight: '56px',
                    color: alpha(String(theme.colors?.newDarkBlue), 0.6)
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    padding: '16px 0',
                    borderRadius: '16px'
                },
                root: {
                    '&.position-bottom': {
                        '& .MuiDialog-container': {
                            alignItems: 'flex-end'
                        },

                        '& .MuiPaper-root': {
                            margin: 0,
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                            maxWidth: '100vw',
                            width: '100%'
                        }
                    }
                }
            }
        },

        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontSize: '1.25rem',
                    padding: '16px'
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    paddingLeft: '16px',
                    paddingRight: '16px'
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    paddingLeft: '16px',
                    paddingRight: '16px'
                }
            }
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    '&.cb-table-container': {
                        padding: '12px',
                        border: `1px solid ${theme.colors?.grey200}`,
                        borderRadius: '8px'
                    }
                }
            }
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    '&.table-borderless .MuiTableCell-root': {
                        border: 'none !important'
                    },

                    '&.table-striped': {
                        '& .MuiTableCell-root': {
                            color: theme.colors?.grey800
                        },

                        '& .MuiTableRow-root:nth-of-type(even):not(.MuiTableRow-hover:hover)': {
                            backgroundColor: 'rgba(241, 243, 249, 0.3)' // grey.200?
                        },

                        '& .MuiTableCell-head': {
                            color: theme.colors?.primary900,
                            backgroundColor: 'rgba(241, 243, 249, 1)',

                            '&:first-of-type': {
                                borderTopLeftRadius: '4px',
                                borderBottomLeftRadius: '4px'
                            },

                            '&:last-of-type': {
                                borderTopRightRadius: '4px',
                                borderBottomRightRadius: '4px'
                            }
                        }
                    }
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderColor: theme?.customization?.navType === 'dark' ? theme.colors?.darkTextPrimary! + 15 : theme.colors?.grey200,
                    '&.MuiTableCell-head': {
                        fontSize: '0.875rem',
                        color: theme.heading,
                        fontWeight: 500
                    }
                },

                sizeSmall: {
                    height: '42px',
                    paddingLeft: '8px',
                    paddingRight: '8px'
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: '0.75rem',
                    color: theme.paper,
                    background: theme.colors?.newDarkBlue,
                    padding: '8px 12px',
                    borderRadius: '12px'
                },
                arrow: {
                    color: theme.colors?.newDarkBlue
                }
            }
        },
        MuiSnackbar: {
            styleOverrides: {
                root: {
                    '&.MuiSnackbar-anchorOriginCenterCenter': {
                        bottom: '50% !important',
                        transform: 'translate(-50%)'
                    }
                }
            }
        },
        MuiSwitch: {
            defaultProps: {
                disableRipple: true,
                disableTouchRipple: true,
                disableFocusRipple: true
            },

            styleOverrides: {
                root: {
                    color: '#fff',
                    padding: 0,

                    '& .MuiTouchRipple-root': {
                        display: 'none'
                    }
                },

                thumb: {
                    backgroundColor: theme.colors?.newDarkBlue,
                    '.Mui-checked &': {
                        backgroundColor: '#fff'
                    }
                },

                track: {
                    opacity: '1 !important',
                    backgroundColor: '#fff',
                    border: `2px solid ${theme.colors?.newDarkBlue}`,

                    '.Mui-checked+&': {
                        border: 'none'
                    }
                },

                sizeMedium: {
                    height: '32px',
                    width: '52px',

                    '& .MuiSwitch-track': {
                        borderRadius: '16px'
                    },

                    '& .MuiSwitch-thumb': {
                        width: '16px',
                        height: '16px'
                    },

                    '& .MuiSwitch-switchBase': {
                        padding: '8px 6px'
                    },

                    '& .Mui-checked.MuiSwitch-switchBase': {
                        padding: '4px',
                        '& .MuiSwitch-thumb': {
                            width: '24px',
                            height: '24px'
                        }
                    }
                },

                sizeSmall: {
                    '& .MuiSwitch-track': {
                        borderRadius: '12px',
                        borderWidth: '1px'
                    }
                }
            }
        },

        MuiTableSortLabel: {
            styleOverrides: {
                root: {
                    '&, &.Mui-active': {
                        color: 'inherit',
                        '& .MuiSvgIcon-root': {
                            color: theme.colors?.primaryMain
                        }
                    }
                }
            }
        },

        MuiRadio: {
            styleOverrides: {
                root: {
                    '&, &.Mui-checked': {
                        color: theme.colors?.newDarkBlue
                    }
                }
            }
        },

        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: theme.colors?.newDarkBlue,

                    '&.MuiCheckbox-colorPrimary': {
                        '&.Mui-checked': {
                            color: theme.colors?.newDarkBlue
                        }
                    }
                }
            }
        },

        MuiPaginationItem: {
            styleOverrides: {
                text: {
                    '&:not(.Mui-selected)': {
                        border: `1px solid ${theme.colors?.grey200}`
                    }
                }
            }
        },

        MuiBreadcrumbs: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                    lineHeight: '18px',
                    color: theme.colors?.newDarkBlue,

                    '& .MuiBreadcrumbs-li': {
                        '& > a': {
                            color: 'inherit',
                            fontSize: 'inherit',
                            lineHeight: 'inherit',
                            textDecoration: 'none',
                            opacity: '0.6'
                        },

                        '& > span': {
                            fontWeight: 600
                        }
                    },

                    '& .MuiBreadcrumbs-separator': {
                        marginLeft: '12px',
                        marginRight: '12px',
                        '& .MuiSvgIcon-root': { fontSize: '12px !important' }
                    }
                }
            }
        }
    };
}
