import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { IconCurrencyDollar } from '@tabler/icons';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ClearIcon from '@mui/icons-material/Clear';

// material-ui
import {
    Avatar,
    Divider,
    Link,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
    Typography,
    useTheme,
    Box,
    Switch,
    Tooltip,
    Stack,
    IconButton
} from '@mui/material';
import ManageAccounts from '@mui/icons-material/ManageAccounts';

// project imports
import useAuth from 'hooks/useAuth';
import preferredImageSize from '../../../../utils/preferred-image-size';

// assets
import { colors } from '../../../../store/constant';
import QRCode from 'react-qr-code';
import CBModal from '../../../../ui-component/cb-modal/CBModal';
import QrCodeDialogToggle from '../../../../ui-component/QrCodeDialogToggle';
import useProviderUrl from '../../../../views/link-tree/use-provider-url';
import accountService from '../../../../services/AccountService';
import useShowSnackbar from '../../../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../../../store/snackbarReducer';
import EmployeeRoleChip from '../../../../ui-component/EmployeeRoleChip';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = ({ isMobile }: { isMobile: boolean }) => {
    const [profileCardEnabled, setProfileCardEnabled] = useState<boolean | null>(null);
    const [showQr, setShowQr] = useState(false);
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedIndex] = React.useState(1);
    const { showSnackbar } = useShowSnackbar();
    const { logout, user } = useAuth();
    const { data: cardData, isLoading: cardDataLoading } = accountService.useGetAccountCardDataQuery(null);
    const [save, { isLoading: cardDataSaving }] = accountService.useUpdateAccountCardDataMutation();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const openMenu = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const closeMenu = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleLogout = async () => {
        try {
            await logout();
        } catch (err) {
            console.error(err);
        }
        location.state = null;
    };

    const handleOpenSettings = () => {
        navigate('/profile');
    };

    const handleOpenLinkTree = useCallback(() => {
        if (!(cardDataLoading || cardDataSaving)) {
            navigate('/profile-card');
        }
    }, [cardDataLoading, cardDataSaving, navigate]);

    const handleToggleCard = useCallback(
        (enabled: boolean) => {
            setProfileCardEnabled(enabled);
            save({ ...cardData, slug: cardData?.slug ?? '', enabled })
                .unwrap()
                .then(() => {
                    if (!enabled) {
                        navigate('/calendar');
                    }
                })
                .catch((err) => {
                    showSnackbar({
                        alertSeverity: SnackBarTypes.Error,
                        message: err.data || err.message || JSON.stringify(err)
                    });
                    setProfileCardEnabled((prev) => !prev);
                });
        },
        [cardData, navigate, save, showSnackbar]
    );

    const { providerUrl } = useProviderUrl();

    const cardDataEnabled = Boolean(cardData?.enabled);

    // Watch for changes
    useEffect(() => {
        setProfileCardEnabled(cardDataEnabled);
    }, [cardDataEnabled]);

    if (!user) {
        return null;
    }

    return (
        <>
            <Avatar
                src={preferredImageSize(user.avatar, 'small')}
                aria-controls={anchorEl ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                color="inherit"
                sx={{
                    backgroundColor: user.employee.background_color ? `#${user.employee.background_color}` : colors.blue.value,
                    cursor: 'pointer',
                    width: '40px',
                    height: '40px',
                    fontSize: '1.2rem'
                }}
                onClick={openMenu}
            >
                <Typography
                    fontSize="inherit"
                    sx={{
                        color: theme.palette.getContrastText(
                            user.employee.background_color ? `#${user.employee.background_color}` : colors.blue.value
                        )
                    }}
                >
                    {user.title.charAt(0).toUpperCase()}
                </Typography>
            </Avatar>

            <Menu
                slotProps={{
                    paper: {
                        sx: {
                            borderRadius: '12px',
                            backgroundColor: 'grey.50',
                            boxShadow: ' 0px 1px 8px 0px rgba(53, 53, 53, 0.25)',

                            '& > .MuiList-root': {
                                padding: '0 !important',
                                '& .MuiListItemIcon-root, & .MuiListItemText-root, & .MuiTypography-root, & .MuiListItemIcon-root': {
                                    color: 'inherit'
                                }
                            }
                        }
                    }
                }}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={closeMenu}
            >
                <Box p={2}>
                    <Stack alignItems="center" spacing={1} pb={2} sx={{ color: 'grey.800' }}>
                        <IconButton size="small" color="inherit" sx={{ alignSelf: 'flex-end' }} onClick={closeMenu}>
                            <ClearIcon />
                        </IconButton>
                        <EmployeeRoleChip role={user.employee.role.name} size="medium" />
                        <Typography variant="h4" sx={{ fontWeight: 600, fontSize: '1.5rem', lineHeight: '1.16', color: 'inherit' }}>
                            {`Hi, ${user?.title}`}
                        </Typography>
                    </Stack>
                    <Divider sx={{ my: 1 }} />
                    <List
                        component="nav"
                        sx={{
                            padding: 0,
                            width: '100%',
                            maxWidth: '350px',
                            minWidth: '300px',
                            [theme.breakpoints.down('sm')]: {
                                minWidth: '100%'
                            },

                            '& .MuiListItemButton-root': {
                                color: 'newColors.darkBlue',
                                lineHeight: '1.25rem',
                                fontSize: '0.875rem',
                                py: 1,
                                px: 1.5,
                                borderRadius: '8px',

                                '&:hover': {
                                    color: 'secondary.main',

                                    '& .MuiListItemIcon-root': {
                                        color: 'inherit'
                                    }
                                },

                                '& .MuiListItemText-root': {
                                    margin: 0
                                }
                            }
                        }}
                    >
                        <ListItemButton selected={selectedIndex === 4} onClick={handleOpenSettings}>
                            <ListItemIcon>
                                <ManageAccounts />
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="body2">Profile Settings</Typography>} />
                        </ListItemButton>

                        {user.site_url && (
                            <Link onClick={handleOpenLinkTree} underline="none">
                                <ListItemButton>
                                    <ListItemIcon>
                                        <AccountBoxIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                component="div"
                                                variant="body2"
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                Profile Card
                                                <Box>
                                                    <Tooltip title="Toogle Profile Card">
                                                        <span>
                                                            <Switch
                                                                disabled={cardDataLoading || cardDataSaving}
                                                                onClick={(e) => e.stopPropagation()}
                                                                checked={Boolean(profileCardEnabled)}
                                                                onChange={(_, checked) => handleToggleCard(checked)}
                                                                color="secondary"
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                    {providerUrl ? (
                                                        <QrCodeDialogToggle
                                                            color="secondary"
                                                            sx={{
                                                                padding: 0,
                                                                width: '30px',
                                                                height: '30px',
                                                                ml: 1
                                                            }}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setShowQr(true);
                                                            }}
                                                        />
                                                    ) : null}
                                                </Box>
                                            </Typography>
                                        }
                                    />
                                </ListItemButton>
                            </Link>
                        )}
                        {user.stripe_billing_portal_url && (
                            <>
                                <Divider sx={{ my: 1 }} />
                                <Link href={user.stripe_billing_portal_url} target="_blank" underline="none">
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <IconCurrencyDollar stroke={1.5} size="1.3rem" />
                                        </ListItemIcon>
                                        <ListItemText primary={<Typography variant="body2">Billing Portal</Typography>} />
                                    </ListItemButton>
                                </Link>
                            </>
                        )}
                        <Divider sx={{ my: 1 }} />
                        <ListItemButton selected={selectedIndex === 4} onClick={handleLogout}>
                            <ListItemIcon>
                                <LogoutOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                        </ListItemButton>
                    </List>
                </Box>
            </Menu>

            {providerUrl ? (
                <CBModal open={showQr} onClose={() => setShowQr(false)} title="Provider Link">
                    <QRCode value={providerUrl} />
                </CBModal>
            ) : null}
        </>
    );
};

export default ProfileSection;
