import { IEmployee } from './IEmployee';
import { ILocation } from './ILocation';
import { ImageData } from './IImage';
import { IWaiver } from './ICompany';

export enum ServiceLocationType {
    Business = 'business',
    External = 'external',
    Virtual = 'virtual'
}

export interface IWeekDaySchedule {
    id: number;
    enable: boolean;
}

export interface IServiceMaterials {
    name: string;
    units: string;
    price_per_unit: number;
}

export interface IServicePivotMaterials extends IServiceMaterials {
    amount: number;
}

export type ServiceQuestionType = {
    name: string;
    type: string;
    title: string;
    answer?: string | null;
};

export type ServiceAnswerType = {
    title: string;
    answer: string;
};

type ServicePivotType = {
    id: number;
    payment_type: PaymentType;
    price: number | null;
    prepay: number | null;
    use_materials: boolean;
    materials: IServicePivotMaterials;
    widget_answers?: ServiceAnswerType[] | null;
    waiver_pdf?: string | null;
    waiver_signed_at?: string | null;
    waiver_answers?: Record<string, string> | null;
    waiver_name?: string | null;
};

export interface IService {
    id: number;
    name: string;
    duration: number | null;
    interval: number | null;
    is_reschedule_enabled: boolean;
    rescheduling_interval: number;
    pivot?: ServicePivotType;
    payment_type: PaymentType;
    price: number | null;
    prepay: number | null;
    locations?: ILocation[];
    employees?: IEmployee[];
    deleted_at?: string | null;
    images: ImageData[];
    description: string | null;
    schedule: IWeekDaySchedule[];
    sorting_order: number;
    location_type: ServiceLocationType;
    is_private: boolean;
    slug: string;
    confirmation_note: string;
    isActive?: boolean;
    advance_booking_buffer?: number;
    is_waiver_enabled?: boolean;
    waivers?: IWaiver[];
    staff_autoassign: boolean;
    use_materials?: boolean;
    materials?: IServiceMaterials | null;
    use_rebook_reminder: boolean;
    use_service_policy: {
        enabled: boolean;
        policy_title?: string | null;
        policy_text?: string | null;
    };
    widget_questions?: ServiceQuestionType[] | null;
    requires_confirmation: {
        from_customer: boolean;
        from_employee?: boolean | null;
    };
    custom_success_url?: string | null;
}

export enum PaymentType {
    Free = 'free',
    Paid = 'paid',
    Prepaid = 'prepay'
}

export enum PaymentTypeNames {
    Free = 'Free',
    Paid = 'Paid',
    Prepaid = 'Deposit Required'
}

export interface ServiceOrderPayload {
    ordering: IServiceOrder[];
}

export interface IServiceOrder {
    id: number;
    sorting_order: number;
}
