import { useAppDispatch } from '../../../../hooks/redux';
import { useCallback, useMemo } from 'react';
import { FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, Select } from '@mui/material';
import { AppointmentStatuses } from '../../../../models/IAppointment';
import { styled } from '@mui/material/styles';
import { setSelectedEventStatus } from '../../../../store/slices/calendarSlice';
import useDialogFunctions from '../../../../hooks/useDialogFunctions';
import CancellationReasonSelect from './elements/CancellationReasonSelect';
import { axiosServices } from '../../../../utils/axios';
import { CancellationReason } from '../../../../views/calendar/types';
import useShowSnackbar from '../../../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../../../store/snackbarReducer';
import useGetColorByStatus from '../../../../hooks/useGetColorByStatus';
import { startSubmitting, stopSubmitting } from '../../../../store/slices/SubmittingSlice';
import { FormikValues } from 'formik';
import appointmentStatuses from '../../../../views/calendar/constants/appointment-statuses';
import RebookReminderSelect from './elements/RebookReminderSelect';
import appointmentAPI from '../../../../services/AppointmentService';

const StyledMenuItem = styled(MenuItem)(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: '5px',

    '& .MuiListItemIcon-root': {
        minWidth: 'auto',

        '& .MuiSvgIcon-root': {
            fontSize: '22px'
        }
    }
}));

const StyledSelect = styled(Select, {
    shouldForwardProp: (prop) => prop !== 'borderColor'
})<{ borderColor?: string }>(({ borderColor }) => ({
    height: '44px',

    '& .MuiSelect-select': {
        display: 'flex',
        alignItems: 'center',
        gap: '5px',

        '& .MuiListItemIcon-root': {
            minWidth: 'auto',

            '& .MuiSvgIcon-root': {
                fontSize: '22px'
            }
        },

        '& .MuiListItemText-root': {
            margin: 0,
            display: 'none'
        }
    },

    '& .MuiSelect-icon.Mui-disabled': {
        visibility: 'hidden'
    },

    '& fieldset': {
        borderColor: `${borderColor} !important`
    }
}));

interface StatusSelectProps {
    values: FormikValues;
    setFieldValue: (fieldName: string, value: any) => void;
    disabled?: boolean;
    useRebookReminder?: boolean;
}

const StatusSelect = ({ values, setFieldValue, disabled, useRebookReminder }: StatusSelectProps) => {
    const dispatch = useAppDispatch();
    const { showSnackbar } = useShowSnackbar();
    const { getColorByStatus } = useGetColorByStatus();

    const { isDialogOpen: cancelReasonOpen, openDialog: openCancelReason, closeDialog: closeCancelReason } = useDialogFunctions();
    const { isDialogOpen: reminderOpen, openDialog: openReminder, closeDialog: closeReminder } = useDialogFunctions();

    const status = useMemo(() => values.status, [values]);

    const statuses = appointmentStatuses;

    const updateStatus = useCallback(
        (newStatus: AppointmentStatuses, reason?: CancellationReason, remind_after_interval?: string) => {
            dispatch(startSubmitting());

            axiosServices
                .put(`/appointments/${values?.id}/status`, {
                    status: newStatus,
                    cancel_reason: reason,
                    remind_after_interval
                })
                .then(() => {
                    setFieldValue('status', newStatus);
                    dispatch(setSelectedEventStatus(newStatus));
                    dispatch(appointmentAPI.util.invalidateTags(['Appointment']));
                })
                .catch((err) => {
                    showSnackbar({
                        message: err.message,
                        alertSeverity: SnackBarTypes.Error
                    });
                })
                .finally(() => {
                    dispatch(stopSubmitting());
                });
        },
        [dispatch, values.id, setFieldValue, showSnackbar]
    );

    const handleChange = (event: { target: { value: AppointmentStatuses } }) => {
        const newStatus = event.target.value;
        if (newStatus === AppointmentStatuses.Canceled) {
            openCancelReason();
            return;
        }

        if (newStatus === AppointmentStatuses.Completed && useRebookReminder) {
            openReminder();
            return;
        }

        updateStatus(newStatus);
    };

    const setReminder = useCallback(
        (remind_after_interval: string | undefined) => {
            updateStatus(AppointmentStatuses.Completed, undefined, remind_after_interval);
            closeReminder();
        },
        [closeReminder, updateStatus]
    );

    return values?.id ? (
        <>
            <FormControl fullWidth>
                <InputLabel id="status-select-label">Status</InputLabel>
                <StyledSelect
                    labelId="status-select-label"
                    id="status-select"
                    value={status || ''}
                    label="Status"
                    // @ts-ignore
                    onChange={(e) => handleChange(e)}
                    borderColor={getColorByStatus(status || '')}
                    disabled={disabled}
                    MenuProps={{
                        disableScrollLock: true
                    }}
                >
                    {statuses.map((s) => (
                        <StyledMenuItem key={`status-${s.value}`} value={s.value}>
                            <ListItemIcon sx={{ color: getColorByStatus(s.value) }}>{s.icon}</ListItemIcon>
                            <ListItemText>
                                <span
                                    style={{
                                        color: getColorByStatus(s.value),
                                        fontWeight: 'bold',
                                        fill: 'currentColor',
                                        WebkitTextFillColor: 'currentColor'
                                    }}
                                >
                                    {s.label}
                                </span>
                            </ListItemText>
                        </StyledMenuItem>
                    ))}
                </StyledSelect>
            </FormControl>
            <CancellationReasonSelect isOpen={cancelReasonOpen} close={closeCancelReason} updateStatus={updateStatus} />
            <RebookReminderSelect isOpen={reminderOpen} action={setReminder} />
        </>
    ) : null;
};

export default StatusSelect;
