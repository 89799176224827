import { IAddress } from './IAddress';
import { ImageData } from './IImage';
import { IAppointment } from './IAppointment';
import { ILocation } from './ILocation';
import { IService } from './IService';
import { EmployeeCommissionType, IEmployee } from './IEmployee';
import { IUser } from './IUser';
import { TimeSlot } from '../views/scheduling-widget/widget-wizard/types';
import { ISalesTaxSettings } from './ISalesTaxSettings';

export enum SubscriptionType {
    SingleUser = 'single_user',
    Organization = 'organization',
    Agency = 'agency',
    Deactivated = 'deactivated',
    SmallBusiness = 'small_business'
}

export enum WaiverSignType {
    PerAppointment = 'per_appointment',
    PerCustomer = 'per_customer'
}

export enum MeetingProvidersType {
    Whereby = 'whereby',
    Zoom = 'zoom'
}

export type CompanyCBSettingsType = {
    cb: {
        customers: {
            campaign_identifier_enabled: boolean | null;
        };
    };
};

export interface IUserCompany {
    id: number;
    slug: string;
    name: string;
    time_zone: string;
    logo: ImageData | null;
    uuid: string;
    logo_rectangular: ImageData | null;
    cb_settings: CompanyCBSettingsType;
}

export interface ICompany extends IUserCompany {
    description: string | null;
    email: string | null | undefined;
    phone: string | null;
    site: string | null;
    address: IAddress;
    locations_count: number;
    employees_count: number;
    services_count: number;
    deleted_at?: string | null;
    employee_id?: number;
    is_notifications_enabled: boolean;
    settings: ICompanySettings;
    locations?: ILocation[];
    services?: IService[];
    employees?: IEmployee[];
    is_twilio_enabled: boolean;
    owner?: IUser;
    users?: IUser[];
    inbox_status?: { inbox: boolean; sms: boolean };
}

export interface IWidgetCompany {
    name: string;
    logo: ImageData | null;
    logo_rectangular: ImageData | null;
    phone: string;
    email: string;
    customer?: WidgetCustomer;
    services?: IService[];
    filteredServices?: IService[];
    filteredLocations?: ILocation[];
    filteredEmployees?: IEmployee[];
    settings: {
        widget: IWidgetSettings;
        appointments: {
            waitlist: {
                enabled: boolean;
                any_employee: boolean;
            };
        };
    };
    payment_gws?: PaymentGateways;
    slug: string;
    is_coupons_allowed?: boolean;
}

export interface WidgetCustomer {
    id: number;
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    appointments: IAppointment[];
}

export interface IPaymentIntegrations {
    cc_processor?: string;
    paypal: {
        client_id?: string;
        client_secret?: string;
    };
    authorize_net: {
        api_login_id?: string;
        transaction_key?: string;
    };
    stripe: {
        secret_key?: string;
        publishable_key?: string;
    };
}

export interface ICompanyIntegrations extends IPaymentIntegrations {
    reputation_management?: string;
    gradeus: {
        api_key?: string;
        profile_id?: string;
    };
    reviewshake: {
        api_key?: string;
        subdomain?: string;
        custom_domain?: string;
        campaign?: string;
        client?: string;
        location_slug?: string;
    };
    twilio: {
        auth_token?: string;
        account_sid?: string;
    };
    meetings: MeetingProvidersType;
    zoom?: {
        account_id: string | null;
        client_id: string | null;
        client_secret: null;
    } | null;
}

export interface ICustomerNotificationSettings {
    appointment_deposit_request_reminder: number;
    appointment_invite_reminder: {
        first: number;
        final: number;
    };
    appointment_reminder: {
        virtual_service: number;
        service: number;
    };
    rebook_reminder_follow_up: number;
}

type CommissionSettings = {
    type: EmployeeCommissionType;
    amount: number;
};

export interface ICompanySettings {
    notifications: {
        enabled: boolean;
        immediately_sms_notify?: boolean;
        delay: number;
        customer: ICustomerNotificationSettings;
        check_in_sms_notify?: boolean | null;
    };
    appointments: {
        autocomplete: {
            enabled: boolean;
            interval: number | null;
        };
        completed_notify_customers: boolean;
        no_show_deposit: {
            enabled: boolean;
            percent: number;
        };
        use_modal_view: boolean;
        use_contact_privacy: boolean;
        use_products: boolean;
        waitlist: {
            enabled: boolean;
            any_employee: boolean;
        };
    };
    widget: IWidgetSettings;
    integrations: ICompanyIntegrations;
    enable_commissions: boolean;
    default_commission?: {
        products: CommissionSettings;
        services: CommissionSettings;
    } | null;
    sales_tax: ISalesTaxSettings;
    payments: {
        use_cash_ledger: boolean;
    };
}

export interface ICompanySettingsPayload {
    companyId: number;
    settings: ICompanySettings;
}

export interface IWidgetSettings {
    primaryColor?: string;
    textColor?: string;
    buttonColor?: string;
    bgPattern?: number;
    widgetBgPattern?: {
        start: string;
        end: string;
    };
    is_attachments_enabled: boolean;
    max_advance_booking: number;
    confirmation_note?: string;
    link_builder?: IWidgetLinkBuilder[];
    deposit_text?: string;
    custom_css?: string;
    use_multiservices: boolean;
    slots: {
        use_fixed_size: boolean;
        size: number;
    };
}

export interface IWidgetLinkBuilder {
    location_id: number | null;
    service_ids: number[];
    employee_id: number | null;
    selector?: string | null;
}

export interface PaymentGateways {
    paypal?: {
        is_enabled?: boolean;
        client_id?: string;
    };
    authorize_net?: {
        is_enabled: boolean;
        service_fee_amount: number;
    };
    stripe?: {
        is_enabled: boolean;
        publishable_key: string;
    };
}

export const WidgetBgPatterns = [
    { start: '#2f2f2f', end: '#0b0b0b' },
    { start: '#f894a4', end: '#f9d1b7' },
    { start: '#7c98b3', end: '#637081' },
    { start: '#bfc7d7', end: '#e7eff9' },
    { start: '#334c94', end: '#131d38' },
    { start: '#3e2f69', end: '#10013b' }
];

export interface IStripePaymentIntent {
    result: boolean;
    client_secret: string;
    return_url?: string;
}

export interface IStripePaymentDetails {
    service_ids?: number[];
    appointment_uuid?: string;
    appointment_invite_uuid?: string;
    appointment_selfcheckout_uuid?: string;
    selfcheckout_tip_amount?: number;
}

export interface IStripeAdditionalData {
    // Scheduling Widget
    service_ids?: number[];
    location_id?: number;
    employee_id?: number;
    start_at?: string;
    end_at?: string;
    time_zone?: string;
    images?: string[];
    note?: string;
    coupon_code?: string;

    user?: {
        email?: string;
        firstname?: string;
        lastname?: string;
        note?: string | null;
        phone?: string;
    };

    // Invite Form
    slot?: TimeSlot | null;

    // Self checkout
    selfcheckout_tip_amount?: number | '';
    appointment_uuid?: string;
    company_slug?: string;
}

export interface IStripePaymentIntentInput extends IStripePaymentDetails {
    slug: string;
}

export interface ITwilioNumber {
    phone_number: string;
    friendly_name: string;
}

export interface IWaiver {
    id?: number;
    title: string;
    services?: IService[];
    data?: string | null;
    sign_type?: WaiverSignType;
}

export enum InsightChartType {
    AppointmentVelocity = 'appointment_velocity',
    GrossRevenue = 'gross_revenue',
    ProjectedRevenue = 'projected_revenue',
    AppointmentSourceStaff = 'appointment_source_staff',
    AppointmentSourceRole = 'appointment_source_role',
    ServiceBookings = 'service_bookings'
}

export type CompanyInsightsParams = {
    date_from: string;
    date_to: string;
    chart: InsightChartType;
    group_by?: 'day' | 'month';
};

export type CompanyInsightsInput = {
    company_id: number;
    params: CompanyInsightsParams;
};

export type CompanyInsightsItem = {
    periodStart: string;
    data: {
        created: number;
        cancelled: number;
        net: number;

        // Gross && Projected Revenue
        price: number;
        total: number;
        number: number;

        // Service Bookings
        other?: number;
        firstService?: [number, string];
        secondService?: [number, string];
        thirdService?: [number, string];
        servicesData?: [number, string][];
    };

    // Source widgets
    label: string;
    amount: number;
};

export interface ICompanyPayload {
    name: string;
    email?: string | null;
    phone?: string | null;
    site?: string | null;
    industry?: string | null;
    address: IAddress;
    logo?: string | null;
    logo_rectangular?: string | null;
}
