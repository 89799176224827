import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import moment from 'moment';
import { methodOptions, reasonOptions } from '../../../../../../../components/PaymentInfoDialog/PaymentInfoSelectOptions';
import CurrencyFormat from 'react-currency-format';
import { useAppSelector } from '../../../../../../../../../hooks/redux';
import ActionsCell from './elements/ActionsCell';
import { useCallback, useMemo } from 'react';
import { IPayment } from '../../../../../../../../../models/IPayment';

interface IOrderedPayment extends IPayment {
    originalIndex: number;
}

interface PaymentsTableProps {
    onClickDelete: (index: number) => void;
    onClickEdit: (index: number) => void;
}

const Index = ({ onClickDelete, onClickEdit }: PaymentsTableProps) => {
    const { selectedEvent, isForeignAppointment } = useAppSelector((state) => state.calendar);

    const renderAmount = useCallback((payment: IPayment) => {
        const amount = payment.reason === 'coupon' ? payment.coupon?.amount : payment.amount;
        const prefix = payment.reason !== 'coupon' || payment.coupon?.type === 'fixed' ? '$' : undefined;
        const suffix = payment.coupon?.type === 'percent' ? '%' : undefined;

        return (
            <CurrencyFormat
                value={payment.coupon?.type === 'percent' ? parseFloat(`${amount}`) : amount}
                prefix={prefix}
                suffix={suffix}
                displayType="text"
                decimalScale={payment.coupon?.type === 'percent' ? undefined : 2}
                fixedDecimalScale
            />
        );
    }, []);

    const payments = selectedEvent?.payments;

    const orderedPayments = useMemo<IOrderedPayment[]>(() => {
        const arr = payments ?? [];
        return arr.map((payment, originalIndex) => ({ ...payment, originalIndex }));
    }, [payments]);

    return (
        <>
            <TableContainer sx={{ maxHeight: '100%', maxWidth: '100%', overflowX: 'initial' }}>
                <Table
                    size="small"
                    stickyHeader
                    sx={{
                        width: '100%',
                        '& .MuiTableCell-root': {
                            padding: '6px 10px'
                        }
                    }}
                >
                    <TableHead>
                        <TableRow sx={{ '& th': { background: '#fefefa' } }}>
                            <TableCell>Date</TableCell>
                            <TableCell>Method</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell align="right" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orderedPayments.map((payment) => (
                            <TableRow key={`payment-${payment.originalIndex}`}>
                                <TableCell>
                                    {moment(payment.datetime)
                                        .tz(selectedEvent?.location.time_zone || 'UTC')
                                        .format('MM/DD/YY')}
                                </TableCell>
                                <TableCell>
                                    {payment.reason === 'coupon' && payment.code
                                        ? payment.code
                                        : methodOptions.find((m) => m.value === payment.method)?.label}
                                </TableCell>
                                <TableCell>{reasonOptions.find((r) => r.value === payment.reason)?.label}</TableCell>
                                <TableCell>{renderAmount(payment)}</TableCell>
                                <TableCell align="right">
                                    {!isForeignAppointment ? (
                                        <ActionsCell
                                            entity="Payment"
                                            onDelete={() => onClickDelete(payment.originalIndex)}
                                            onEdit={payment.reason !== 'coupon' ? () => onClickEdit(payment.originalIndex) : undefined}
                                        />
                                    ) : null}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {!selectedEvent?.payments?.length && (
                <Typography fontSize={22} textAlign="center" py={2}>
                    No Payments Entered
                </Typography>
            )}
        </>
    );
};

export default Index;
