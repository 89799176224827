import { FC } from 'react';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import ColorizeOutlinedIcon from '@mui/icons-material/ColorizeOutlined';

const ColorInput: FC<TextFieldProps> = ({ label, InputProps, ...rest }) => (
    <TextField
        {...rest}
        label={label ?? 'Color'}
        type="color"
        InputProps={{
            ...InputProps,
            endAdornment: (
                <InputAdornment position="end">
                    <ColorizeOutlinedIcon sx={{ color: 'grey.900' }} />
                </InputAdornment>
            )
        }}
    />
);

export default ColorInput;
