import React, { useCallback, useMemo } from 'react';
import { useAppSelector } from '../../../../../../../../../hooks/redux';
import {
    Box,
    Button,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    GridProps,
    InputAdornment,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import CurrencyFormat from 'react-currency-format';
import { useTheme } from '@material-ui/core';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileDatePicker } from '@mui/x-date-pickers';
import AppTimePicker from '../../../../../../../../../ui-component/form/time-picker/AppTimePicker';
import moment from 'moment/moment';
import DepositRequestReminder from '../../../../../../../../../ui-component/form/deposit-request/DepositRequestReminder';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as Yup from 'yup';
import { depositRequestSchema } from '../../../../../../../../../ui-component/form/deposit-request/DepositRequestForm';
import useAppointmentFunctions from '../../../../../../../components/hooks/useAppointmentFunctions';
import useSaveAppointment from '../../../../../hooks/useSaveAppointment';
import CalendarSvg from '../../../../../../../../../ui-component/wizard/svgs/CalendarSvg';
import useAppointmentDetailsFormFunctions from '../../../../../../../../../hooks/useAppointmentDetailsFormFunctions';
import useShouldSubmitDetailsForm from '../../../../../../../../../hooks/useShouldSubmitDetailsForm';
import { AppointmentStatuses } from '../../../../../../../../../models/IAppointment';
import useAuth from '../../../../../../../../../hooks/useAuth';
import NumberFormat from 'react-number-format';
import useExtendedFormik from '../../../../../../../../../hooks/useExtendedFormik';
import FocusTextField from '../../../../../../../../../ui-component/FocusTextField';
import { SnackBarTypes } from '../../../../../../../../../store/snackbarReducer';
import useShowSnackbar from '../../../../../../../../../hooks/useShowSnackbar';
import appointmentService from '../../../../../../../../../services/AppointmentService';
import { LoadingButton } from '@mui/lab';

const formColumns: Pick<GridProps, 'xs' | 'sm'> = { xs: 6 };

const PaymentsSummary = () => {
    const { user } = useAuth();
    const isMultiServicesEnabled = user && !!user?.currentCompany.settings?.widget?.use_multiservices;
    const { selectedEvent } = useAppSelector((state) => state.calendar);
    const theme = useTheme();
    const { formatAppointmentPayload } = useAppointmentFunctions();
    const { saveAppointment, isSavingAppointment } = useSaveAppointment();
    const [resend, { isLoading: isSending }] = appointmentService.useResendDepositRequestMutation();

    const validationSchema = Yup.object().shape({
        price: !isMultiServicesEnabled ? Yup.number().required('Price is required').min(0.01) : Yup.mixed().notRequired(),
        deposit_request: depositRequestSchema
    });

    const { showSnackbar } = useShowSnackbar();

    const appointmentId = selectedEvent?.id;

    const initialValues = useMemo(
        () => ({
            price: selectedEvent?.services[0]?.pivot?.price ?? 0,
            deposit_request: selectedEvent?.deposit_request
        }),
        [selectedEvent]
    );

    const {
        values,
        errors,
        handleSubmit,
        isSubmitting,
        setSubmitting,
        setFieldValue,
        handleChange,
        handleBlur,
        touched
    } = useExtendedFormik({
        initialValues,
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema,
        onSubmit: (formData) => {
            if (!selectedEvent) return;

            const data = formatAppointmentPayload(selectedEvent);
            data.deposit_request = formData.deposit_request;

            if (!isMultiServicesEnabled) {
                data.service_ids[0].price = formData.price;
            }

            saveAppointment(data, undefined, selectedEvent.id.toString(), () => {
                setSubmitting(false);
            });
        },
        isBlocked: isSavingAppointment
    });

    const totalGratuity = useMemo(() => selectedEvent?.balance_details?.total_gratuity ?? 0, [selectedEvent]);
    const totalPaid = useMemo(() => selectedEvent?.balance_details?.total_paid ?? 0, [selectedEvent]);
    const balanceRemaining = useMemo(() => selectedEvent?.balance_details?.balance_remaining ?? 0, [selectedEvent]);

    const salesTax = useMemo(() => selectedEvent?.balance_details?.sales_tax, [selectedEvent]);

    const depositErrors = React.useMemo(() => {
        if (errors.deposit_request) {
            const str = JSON.stringify(errors.deposit_request);
            try {
                const parsed: {
                    expires_at?: string;
                    amount?: string;
                    reminder?: { value?: string; unit?: string };
                } = JSON.parse(str);
                return parsed ?? undefined;
            } catch {
                return undefined;
            }
        }
        return undefined;
    }, [errors.deposit_request]);

    const reminderErrors = useMemo<string[]>(
        () => (depositErrors?.reminder ? Object.values(depositErrors.reminder).filter((v) => !!v) : []),
        [depositErrors]
    );

    const handleDateUpd = useCallback(
        (date: Date | null, mode: 'date' | 'time') => {
            const cb = (str: string) => setFieldValue('deposit_request.expires_at', str);
            const from = values.deposit_request?.expires_at;
            if (mode === 'time' && date) {
                const hours = moment(date).hours();
                const minutes = moment(date).minutes();
                cb(moment(from).set({ hours, minutes }).toISOString());
            }

            if (mode === 'date' && date) {
                cb(date.toISOString());
            }
        },
        [setFieldValue, values.deposit_request]
    );

    const isDirty = useMemo(() => {
        const priceChanged = parseFloat(String(values.price)).toFixed(2) !== parseFloat(String(initialValues.price)).toFixed(2);
        const changedAmount = initialValues.deposit_request?.amount !== values.deposit_request?.amount;
        const changedExpirationDate =
            new Date(initialValues.deposit_request?.expires_at || 0).toISOString() !==
            new Date(values.deposit_request?.expires_at || 0).toISOString();
        const changedReminderValue = initialValues.deposit_request?.reminder.value !== values.deposit_request?.reminder.value;
        const changedReminderUnit = initialValues.deposit_request?.reminder.unit !== values.deposit_request?.reminder.unit;

        return priceChanged || changedAmount || changedExpirationDate || changedReminderValue || changedReminderUnit;
    }, [initialValues, values]);

    useAppointmentDetailsFormFunctions('appointment-details-service-price-form', isSubmitting);
    useShouldSubmitDetailsForm(isDirty);

    const depositUrl = selectedEvent?.deposit_request?.url;

    const handleDepositCopy = useCallback(() => {
        if (depositUrl) {
            navigator.clipboard.writeText(depositUrl).then(() => {
                showSnackbar({ alertSeverity: SnackBarTypes.Success, message: 'Copied to clipboard!' });
            });
        }
    }, [depositUrl, showSnackbar]);

    const handleResendDeposit = useCallback(() => {
        if (appointmentId) {
            resend(appointmentId)
                .unwrap()
                .then(() => {
                    showSnackbar({
                        alertSeverity: SnackBarTypes.Success,
                        message: 'Deposit request successfully sent'
                    });
                })
                .catch((err) => {
                    showSnackbar({
                        alertSeverity: SnackBarTypes.Error,
                        message: err.data || err.message || JSON.stringify(err)
                    });
                });
        }
    }, [appointmentId, resend, showSnackbar]);

    return (
        <form noValidate onSubmit={handleSubmit} id="appointment-details-service-price-form">
            <Box mb={-2}>
                <Divider sx={{ mt: 0 }} />
                {!isMultiServicesEnabled ? (
                    <>
                        <Box px={2} py={0.5} display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="subtitle1">Service Price</Typography>
                            <NumberFormat
                                customInput={FocusTextField}
                                aria-label="Service Price"
                                id="price"
                                name="price"
                                prefix="$"
                                decimalScale={2}
                                allowNegative={false}
                                value={values.price}
                                onBlur={handleBlur}
                                onValueChange={(v) => {
                                    setFieldValue('price', v.floatValue);
                                }}
                                error={Boolean(touched.price && errors.price)}
                                helperText={touched.price ? errors.price : undefined}
                                size="small"
                                sx={{
                                    maxWidth: '90px',
                                    '& .MuiInputBase-input': { textAlign: 'right', padding: `6px 8px` }
                                }}
                            />
                        </Box>
                        <Divider />
                    </>
                ) : null}
                <Typography variant="subtitle1" px={2} py={1} display="flex" justifyContent="space-between">
                    Total Gratuity
                    <CurrencyFormat value={totalGratuity} prefix="$" displayType="text" decimalScale={2} fixedDecimalScale />
                </Typography>
                <Divider />
                <Typography variant="subtitle1" px={2} py={1} display="flex" justifyContent="space-between">
                    Total Paid
                    <CurrencyFormat value={totalPaid} prefix="$" displayType="text" decimalScale={2} fixedDecimalScale />
                </Typography>
                <Divider />

                {!!salesTax?.enabled && !salesTax.included_in_price && salesTax.amount ? (
                    <>
                        <Typography variant="subtitle1" px={2} py={1} display="flex" justifyContent="space-between">
                            Sales Tax
                            <CurrencyFormat value={salesTax.amount} prefix="$" displayType="text" decimalScale={2} fixedDecimalScale />
                        </Typography>
                        <Divider />
                    </>
                ) : null}

                <Typography
                    variant="subtitle1"
                    px={2}
                    py={1}
                    fontWeight={600}
                    display="flex"
                    justifyContent="space-between"
                    sx={{ background: theme.palette.grey[100] }}
                >
                    Balance Remaining
                    <CurrencyFormat
                        value={balanceRemaining}
                        prefix="$"
                        suffix={!!salesTax?.enabled && !!salesTax?.included_in_price ? ' (incl. tax)' : undefined}
                        displayType="text"
                        decimalScale={2}
                        fixedDecimalScale
                    />
                </Typography>
                {selectedEvent?.deposit_request && selectedEvent?.status === AppointmentStatuses.Pending ? (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Box px={2} pt={3} pb={2}>
                            <Stack direction="row" spacing={1} mb={2} alignItems="center">
                                <Typography variant="subtitle1" fontWeight={600} mr="auto">
                                    Deposit Request
                                </Typography>

                                {depositUrl ? (
                                    <>
                                        <LoadingButton
                                            loading={isSending}
                                            size="small"
                                            variant="contained"
                                            onClick={handleResendDeposit}
                                            disableElevation
                                        >
                                            Resend
                                        </LoadingButton>
                                        <Button size="small" variant="outlined" onClick={handleDepositCopy}>
                                            Copy Link
                                        </Button>
                                    </>
                                ) : null}
                            </Stack>

                            <Grid container spacing={2}>
                                <Grid item {...formColumns}>
                                    <MobileDatePicker
                                        label="Exp. Date"
                                        onChange={(date) => handleDateUpd(date, 'date')}
                                        value={values.deposit_request?.expires_at ? new Date(values.deposit_request.expires_at) : null}
                                        slots={{
                                            textField: (props) => (
                                                <TextField
                                                    {...props}
                                                    fullWidth
                                                    error={!!depositErrors?.expires_at}
                                                    helperText={depositErrors?.expires_at}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <CalendarSvg />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            )
                                        }}
                                        disablePast
                                    />
                                </Grid>
                                <Grid item {...formColumns}>
                                    <FormControl error={!!depositErrors?.expires_at} fullWidth>
                                        <AppTimePicker
                                            label="Exp. Time"
                                            value={
                                                values.deposit_request?.expires_at
                                                    ? moment(values.deposit_request?.expires_at)
                                                    : moment(new Date()).hours(9).minutes(0)
                                            }
                                            onChange={(date) => handleDateUpd(date ? date.toDate() : null, 'time')}
                                        />

                                        <FormHelperText>{depositErrors?.expires_at}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} {...formColumns}>
                                    <TextField
                                        id="deposit_request.amount"
                                        name="deposit_request.amount"
                                        label="Amount"
                                        value={values.deposit_request?.amount ?? 0}
                                        onChange={handleChange}
                                        fullWidth
                                        InputProps={{ startAdornment: '$' }}
                                        error={!!depositErrors?.amount}
                                        helperText={depositErrors?.amount}
                                    />
                                </Grid>
                                <Grid item xs={6} {...formColumns}>
                                    <DepositRequestReminder
                                        prefix="deposit_request"
                                        reminderValue={values.deposit_request?.reminder?.value ?? 1}
                                        reminderUnit={values.deposit_request?.reminder?.unit ?? 'hours'}
                                        onChangeValue={(v) => setFieldValue('deposit_request.reminder.value', v)}
                                        onChangeAmount={(v) => setFieldValue('deposit_request.reminder.unit', v)}
                                        errors={reminderErrors}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </LocalizationProvider>
                ) : null}
            </Box>
        </form>
    );
};

export default PaymentsSummary;
