import { FC, useCallback, useContext, useMemo } from 'react';
import { SearchOptionProps } from '../models';
import { Avatar, Box, MenuItem, Typography } from '@mui/material';
import { stringToColor } from '../../../store/constant';
import { format } from 'date-fns';
import appointmentStatuses from '../../../views/calendar/constants/appointment-statuses';
import { AppointmentStatuses } from '../../../models/IAppointment';
import { AbilityContext } from '../../../utils/roles/Can';
import OptionTextWrapper from './OptionTextWrapper';

const AppointmentOption: FC<SearchOptionProps> = ({ option, onClick, isMobile }) => {
    const ability = useContext(AbilityContext);
    const label = useMemo(() => appointmentStatuses.find((status) => status.value === option.details.status)?.label ?? null, [
        option.details.status
    ]);

    const canSeeAppointmentDetails = useMemo(() => ability.can('view', 'appointment'), [ability]);

    const getStatusColor = useCallback((s: string) => {
        switch (s) {
            case AppointmentStatuses.Completed:
                return 'success';
            case AppointmentStatuses.Canceled:
                return 'error';
            case AppointmentStatuses.Pending:
                return 'warning';
            default:
                return 'primary';
        }
    }, []);

    return (
        <MenuItem
            disableRipple={!canSeeAppointmentDetails}
            disableTouchRipple={!canSeeAppointmentDetails}
            sx={{
                paddingTop: 1,
                paddingBottom: 1,
                '&:hover': { background: canSeeAppointmentDetails ? 'rgba(237, 231, 246, 0.70)' : 'transparent' },
                whiteSpace: 'normal',
                cursor: canSeeAppointmentDetails ? 'pointer' : 'default'
            }}
            onClick={canSeeAppointmentDetails ? () => onClick(option.id) : undefined}
        >
            <Box
                sx={{
                    width: '100%',
                    display: 'grid',
                    gridGap: '8px',
                    gridTemplateColumns: `36px ${isMobile ? 90 : 140}px 1fr auto`,
                    alignItems: 'center',
                    color: 'grey.800'
                }}
            >
                <Avatar sx={{ width: '36px', height: '36px', bgcolor: stringToColor(option.label), color: '#fff' }} src={option.image}>
                    <Typography>{option.label.charAt(0)}</Typography>
                </Avatar>
                <OptionTextWrapper>{option.label}</OptionTextWrapper>
                <OptionTextWrapper sx={{ opacity: 0.6 }}>{option.details.customerName}</OptionTextWrapper>
                <Box sx={{ textAlign: 'right' }}>
                    <Box sx={{ color: 'grey.300', fontSize: '0.625rem', fontWeight: 700 }}>
                        {option.details.date ? format(new Date(option.details.date), 'MMM dd | hh:mm a') : '-'}
                    </Box>
                    <Box
                        sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            backgroundColor: `${getStatusColor(option.details.status ?? '')}.light`,
                            fontWeight: 700,
                            fontSize: '0.625rem',
                            lineHeight: '0.875rem',
                            color: 'grey.800',
                            padding: '4px 8px',
                            borderRadius: '11px'
                        }}
                    >
                        <Box
                            sx={{
                                width: '6px',
                                height: '6px',
                                marginRight: '6px',
                                borderRadius: '50%',
                                backgroundColor: `${getStatusColor(option.details.status ?? '')}.main`
                            }}
                        />
                        {label ?? option.details.status ?? ''}
                    </Box>
                </Box>
            </Box>
        </MenuItem>
    );
};

export default AppointmentOption;
