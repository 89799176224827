import { FC } from 'react';
import AppointmentCard from '../../views/calendar/appointment-card/AppointmentCard';

interface IAppointmentDetailsDialogProps {
    id: number | null;
    onClose: () => void;
}

const AppointmentDetailsDialog: FC<IAppointmentDetailsDialogProps> = ({ id, onClose }) =>
    id ? <AppointmentCard cardEvent={String(id)} onCancel={onClose} /> : null;

export default AppointmentDetailsDialog;
