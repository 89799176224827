import { AppointmentStatuses, AppointmentType } from '../../../models/IAppointment';
import AppointmentForm from '../../../ui-component/appointment-form';
import { useAppSelector } from '../../../hooks/redux';
import moment from 'moment/moment';
import { ILocation } from '../../../models/ILocation';
import { useMemo, useState } from 'react';
import UnfilledButton from '../../../ui-component/form/buttons/UnfilledButton';
import { isPast } from 'date-fns';
import { Box, CircularProgress } from '@mui/material';
import customerAPI from '../../../services/CustomerService';
import { skipToken } from '@reduxjs/toolkit/query';
import FilledButton from '../../../ui-component/form/buttons/FilledButton';
import EntityDrawerHeader from '../../../ui-component/entity-drawer-layout/EntityDrawerHeader';
import EntityDrawerContainer from '../../../ui-component/entity-drawer-layout/EntityDrawerContainer';
import EntityDrawerActions from '../../../ui-component/entity-drawer-layout/EntityDrawerActions';
import EntityDrawerContent from '../../../ui-component/entity-drawer-layout/EntityDrawerContent';

interface NewAppointmentModalProps {
    onClose: () => void;
    location: ILocation;
    employeeId: number | null;
    startDate?: string | Date;
    endDate?: string | Date;
    customerId?: number | null;
}

const NewAppointmentModal = ({ onClose, location, employeeId, startDate, endDate, customerId }: NewAppointmentModalProps) => {
    const [depositRequest, setDepositRequest] = useState(false);
    const [appStartDate, setAppStartDate] = useState(startDate);
    const { allEmployees } = useAppSelector((state) => state.calendarFilter);
    const { data: customerData, isLoading: customerLoading } = customerAPI.useGetCustomerQuery(customerId ? String(customerId) : skipToken);
    const { appointmentDetailsFormId, appointmentDetailsSaveButtonDisabled } = useAppSelector((state) => state.calendar);

    const { start, end } = useMemo(
        () => ({
            start: moment(startDate).toISOString(),
            end: moment(endDate ?? startDate)
                .subtract(1, 'seconds')
                .toISOString()
        }),
        [startDate, endDate]
    );

    const defaultCustomer = useMemo(() => {
        if (customerId) {
            return customerData ?? null;
        }
        return null;
    }, [customerData, customerId]);

    const defaultEmployee = useMemo(() => allEmployees.find((e) => e.id === employeeId) || null, [allEmployees, employeeId]);

    const initialData = useMemo(
        () => ({
            status: AppointmentStatuses.Active,
            employee: defaultEmployee,
            location,
            services: [],
            customer: defaultCustomer,
            start_at: start,
            end_at: end,
            type: AppointmentType.Appointment,
            images: [],
            payments: null,
            is_notifications_enabled: true,
            note: '',
            private_note: '',
            deposit_request: null
        }),

        [location, defaultEmployee, defaultCustomer, start, end]
    );

    const canRequestDeposit = useMemo(() => (appStartDate ? !isPast(new Date(appStartDate)) : false), [appStartDate]);

    return (
        <EntityDrawerContainer>
            <EntityDrawerHeader title="Create Appointment" onClose={onClose} />
            <EntityDrawerContent>
                <Box py={2}>
                    {customerLoading ? (
                        <Box
                            sx={{
                                p: 2,
                                minWidth: '600px',
                                maxWidth: '100%',
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    ) : (
                        <AppointmentForm
                            data={initialData}
                            callback={onClose}
                            isDepositRequested={depositRequest}
                            handleCancelDepositRequest={() => setDepositRequest(false)}
                            isNew
                            onStartDateChange={setAppStartDate}
                        />
                    )}
                </Box>
            </EntityDrawerContent>
            <EntityDrawerActions>
                <Box mr="auto" pr={1} className="DialogSpecialContent">
                    <UnfilledButton
                        disabled={!canRequestDeposit || depositRequest || customerLoading}
                        onClick={() => setDepositRequest(true)}
                        text="Request Deposit"
                    />
                </Box>

                <UnfilledButton onClick={onClose} text="Close" size="md" />
                {appointmentDetailsFormId && (
                    <FilledButton
                        type="submit"
                        size="md"
                        disabled={appointmentDetailsSaveButtonDisabled || depositRequest || customerLoading}
                        form={appointmentDetailsFormId}
                        text="Save"
                    />
                )}
            </EntityDrawerActions>
        </EntityDrawerContainer>
    );
};

export default NewAppointmentModal;
