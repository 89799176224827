import { useEffect, useRef } from 'react';

// project imports
import { IAppointment } from '../../../models/IAppointment';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { clearOuterAppointment } from '../../../store/slices/outerAppointmentSlice';
import appointmentAPI from '../../../services/AppointmentService';
import { skipToken } from '@reduxjs/toolkit/query/react';
import AppointmentDetails from './appointment-details';
import { setSelectedEvent as setStoreEvent } from '../../../store/slices/calendarSlice';
import DeleteButton from './components/DeleteButton';
import useGetColorByStatus from '../../../hooks/useGetColorByStatus';
import { openConfirmPopup } from '../../../store/confirmPopupSlice';
import { Box } from '@mui/material';
import UnfilledButton from '../../../ui-component/form/buttons/UnfilledButton';
import FilledButton from '../../../ui-component/form/buttons/FilledButton';
import EntityDrawerHeader from '../../../ui-component/entity-drawer-layout/EntityDrawerHeader';
import EntityWrapperContainer from '../../../ui-component/entity-drawer-layout/EntityDrawerContainer';
import EntityDrawerContent from '../../../ui-component/entity-drawer-layout/EntityDrawerContent';
import EntityDrawerActions from '../../../ui-component/entity-drawer-layout/EntityDrawerActions';

export interface AppointmentCardProps {
    cardEvent: IAppointment | string | undefined;
    onCancel: () => void;
}

const AppointmentCard = ({ cardEvent, onCancel }: AppointmentCardProps) => {
    const dispatch = useAppDispatch();
    const {
        appointmentDetailsFormId,
        appointmentDetailsSaveButtonDisabled,
        selectedEvent: storeEvent,
        isForeignAppointment,
        shouldSubmitFormOnTabChange
    } = useAppSelector((state) => state.calendar);
    const { getColorByStatus } = useGetColorByStatus();
    // event from calendar -> get his id -> fetch full appointment info
    const eventId = typeof cardEvent === 'string' && !isNaN(Number(cardEvent)) ? Number(cardEvent) : undefined;
    const { data: appointment, isLoading, isFetching } = appointmentAPI.useGetAppointmentQuery(eventId ?? skipToken, {
        refetchOnMountOrArgChange: true
    });

    const submitButtonRef = useRef<null | HTMLButtonElement>(null);

    useEffect(() => {
        if (appointment && (!storeEvent || storeEvent.id !== eventId)) {
            dispatch(setStoreEvent(appointment));
        }
    }, [appointment, dispatch, eventId, storeEvent]);

    const closeEventCard = () => {
        onCancel();
        dispatch(clearOuterAppointment());
        dispatch(setStoreEvent(null));
    };

    const onCloseEventCard = () => {
        if (shouldSubmitFormOnTabChange) {
            dispatch(
                openConfirmPopup({
                    text: 'Discard unsaved changes?',
                    confirmText: 'Discard',
                    onConfirm: () => closeEventCard()
                })
            );
        } else {
            closeEventCard();
        }
    };

    const appointmentLoading = isLoading || isFetching;

    return (
        <EntityWrapperContainer>
            <EntityDrawerHeader title="Appointment Details" color={getColorByStatus(storeEvent?.status)} onClose={onCloseEventCard} />

            <EntityDrawerContent>
                <AppointmentDetails submitButtonRef={submitButtonRef} isLoading={appointmentLoading} />
            </EntityDrawerContent>

            {/* Actions */}
            <EntityDrawerActions>
                <Box mr="auto" pr={1} className="DialogSpecialContent">
                    {appointmentLoading ? null : <DeleteButton onSuccess={closeEventCard} disabled={isForeignAppointment} />}
                </Box>

                <UnfilledButton onClick={onCloseEventCard} text="Close" size="md" className="DialogCancelBtn" />
                {appointmentDetailsFormId && (
                    <FilledButton
                        type="submit"
                        size="md"
                        disabled={
                            appointmentDetailsSaveButtonDisabled ||
                            isForeignAppointment ||
                            !shouldSubmitFormOnTabChange ||
                            appointmentLoading
                        }
                        form={appointmentDetailsFormId}
                        text="Save"
                        className="DialogOkBtn"
                        buttonRef={submitButtonRef}
                    />
                )}
            </EntityDrawerActions>
        </EntityWrapperContainer>
    );
};

export default AppointmentCard;
