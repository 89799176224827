import { useCallback, useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

// material-ui
import { makeStyles } from '@material-ui/styles';
import { useTheme, Theme } from '@material-ui/core/styles';
import { AppBar, CssBaseline, Toolbar, useMediaQuery, Alert, Link, Box } from '@material-ui/core';

// third-party
import { hotjar } from 'react-hotjar';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import navigation from 'menu-items';
import { SET_MENU } from 'store/actions';
import { DefaultRootStateProps } from 'types';

// assets
import { IconChevronRight } from '@tabler/icons';
import useAuth from '../../hooks/useAuth';

// casl
import { AbilityContext } from '../../utils/roles/Can';
import { Ability, AbilityBuilder } from '@casl/ability';
import { baseUserActions } from '../../utils/roles/ability';
import { IUser, UserPermission } from '../../models/IUser';
import { useLocation, useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import config from '../../config';
import EntityDialogs from '../../ui-component/entity-dialogs/EntityDialogs';
import useNavigationMode from '../use-navigation-mode';
import { setHeaderHeight } from '../../store/slices/layoutSlice';
import CBBottomNav from '../CBBottomNav';
import CBBreadcrumbs from '../CBBreadcrumbs';
import useAppHeaderHeight from '../../hooks/use-header-height';

// style constant
const useStyles = makeStyles((theme: Theme) => ({
    content: {
        ...theme.typography.mainContent,
        width: 'calc(100% - 400px)',
        flexGrow: 1,
        borderRadius: 0,
        marginLeft: 0,
        marginRight: 0,

        '& > div': {
            border: 'none'
        },
        paddingBottom: 0,
        [theme.breakpoints.down('mobile')]: {
            padding: '0 0 56px 0'
        },

        [theme.breakpoints.down('sm')]: {
            marginTop: '60px'
        }
    }
}));

const updateAbility = (ability: Ability, user: IUser) => {
    const {
        employee: {
            role: { permissions }
        }
    } = user;
    const { can, rules } = new AbilityBuilder(Ability);

    permissions.forEach(({ action, subject }: UserPermission) => {
        if (action === '*') {
            baseUserActions.forEach((baseAction) => {
                can(baseAction, subject);
            });
        } else {
            can(action, subject);
        }
    });

    ability.update(rules);
};

declare global {
    interface Window {
        // $crisp: any;
        // CRISP_WEBSITE_ID: string;
        LiveAgent: any;
    }
}

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const height = useAppHeaderHeight();
    const navMode = useNavigationMode();
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const classes = useStyles();
    const { user } = useAuth();
    const matchSm = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('sm'));
    const { showSupportWidget } = useAppSelector((state) => state.layout);

    useEffect(() => {
        if (location.pathname === '/') {
            navigate(config.defaultPath, { replace: true });
        }
    }, [location, navigate]);

    // Handle left drawer
    const leftDrawerOpened = useSelector((state: DefaultRootStateProps) => state.customization.opened);
    const handleLeftDrawerToggle = useCallback(() => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    }, [dispatch, leftDrawerOpened]);

    const ability = useContext(AbilityContext);

    const toolbarRef = useRef<HTMLDivElement | null>(null);
    const element = toolbarRef?.current;

    useEffect(() => {
        const observer = new ResizeObserver((data) => {
            const elemHeight = data[0]?.contentRect.height ?? 0;
            dispatch(setHeaderHeight(elemHeight));
        });

        if (element) {
            observer.observe(element);
        }

        return () => {
            // Cleanup the observer by unobserving all elements
            dispatch(setHeaderHeight(0));
            observer.disconnect();
        };
    }, [dispatch, element]);

    const roleName = user?.employee.role.name;

    useEffect(() => {
        if (user) {
            updateAbility(ability, user);
        }
    }, [user, roleName, ability]);

    useEffect(() => {
        if (process.env.NODE_ENV !== 'production') return;

        hotjar.initialize(3409396, 6);

        // eslint-disable-next-line func-names
        (function () {
            const s = document.createElement('script');
            s.src = 'https://chilled.ladesk.com/scripts/track.js';
            s.id = 'la_x2s6df8d';
            s.type = 'text/javascript';
            s.async = true;
            s.onload = () => {
                window.LiveAgent.clearAllUserDetails();
                window.LiveAgent.setUserDetails(user?.email, user?.firstname, user?.lastname, user?.phone);
                window.LiveAgent.createButton('nrj5j816', document.getElementById('LiveAgent-chatButton'));
            };
            document.getElementsByTagName('head')[0].appendChild(s);
        })();
    }, [user]);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <AppBar
                id="app-header"
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                ref={toolbarRef}
                sx={{ backgroundColor: 'background.default' }}
            >
                <Toolbar
                    sx={{
                        px: matchSm ? '16px' : '20px !important',
                        py: matchSm ? '10px !important' : undefined,
                        minHeight: matchSm ? '60px !important' : '80px !important'
                    }}
                >
                    <Header handleLeftDrawerToggle={handleLeftDrawerToggle} matchSm={matchSm} />
                </Toolbar>
                <CBBreadcrumbs />
            </AppBar>

            {/* drawer */}
            {navMode === 'sidebar' ? <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} /> : null}

            {/* main content */}
            <Box
                component="main"
                className={classes.content}
                sx={{ marginTop: `${height} !important`, minHeight: `calc(100vh - ${height}) !important` }}
            >
                {user?.is_impersonated && (
                    <Alert severity="error" sx={{ marginBottom: theme.spacing(1), borderRadius: '8px' }}>
                        You&apos;re in the impersonate mode, <Link href="/select-organization">select another user.</Link>
                    </Alert>
                )}
                {/* breadcrumb */}
                <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                <Outlet />
                <CBBottomNav />
            </Box>
            <EntityDialogs />
            <Box sx={{ display: showSupportWidget ? 'inherit' : 'none' }}>
                <div id="LiveAgent-chatButton" />
            </Box>
        </Box>
    );
};

export default MainLayout;
