import { useCallback, useMemo } from 'react';
import moment from 'moment-timezone';

// mui
import { Box, IconButton } from '@mui/material';
import AddBoxOutlined from '@mui/icons-material/AddBoxOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

// project imports
import { AppointmentType, IAppointmentPayload } from '../../../models/IAppointment';
import { UserRole } from '../../../models/IEmployee';
import BlockTimeForm, { BlockTimeFormType } from './BlockTimeForm';
import { ILocation } from '../../../models/ILocation';
import useAuth from '../../../hooks/useAuth';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { startSubmitting, stopSubmitting } from 'store/slices/SubmittingSlice';
import { openConfirmPopup } from '../../../store/confirmPopupSlice';
import { DateRange } from '../types';
import { apiTimeFormat } from '../../../store/constant';
import appointmentAPI from '../../../services/AppointmentService';
import BlockSkeleton from './BlockSkeleton';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { isEventDateValid } from '../../../utils/functions/time-zones-helpers';
import BlockTimeHistory from './history/BlockTimeHistory';
import { SnackBarTypes } from '../../../store/snackbarReducer';
import useShowSnackbar from '../../../hooks/useShowSnackbar';
import appointmentWidgetAPI from '../../../services/WidgetService';
import EntityDrawerContainer from '../../../ui-component/entity-drawer-layout/EntityDrawerContainer';
import EntityDrawerHeader from '../../../ui-component/entity-drawer-layout/EntityDrawerHeader';
import EntityDrawerActions from '../../../ui-component/entity-drawer-layout/EntityDrawerActions';
import UnfilledButton from '../../../ui-component/form/buttons/UnfilledButton';
import FilledButton from '../../../ui-component/form/buttons/FilledButton';
import EntityDrawerContent from '../../../ui-component/entity-drawer-layout/EntityDrawerContent';

interface BlockTimeDialogProps {
    onClose: () => void;
    location: ILocation;
    eventId: number | undefined;
    range: DateRange | null;
    selectedEmployeeId: number | null;
}

const BlockTimeDialog = ({ onClose, range, eventId, location, selectedEmployeeId }: BlockTimeDialogProps) => {
    const { allEmployees: employees } = useAppSelector((state) => state.calendarFilter);
    const dispatch = useAppDispatch();
    const { showSnackbar } = useShowSnackbar();
    const { user } = useAuth();
    const { data: block, isLoading: isLoadingBlock, isFetching } = appointmentAPI.useGetAppointmentQuery(eventId ?? skipToken);
    const [createAppointment, { isLoading: isCreating }] = appointmentAPI.useCreateAppointmentMutation();
    const [updateAppointment, { isLoading: isUpdating }] = appointmentAPI.useUpdateAppointmentMutation();
    const [deleteAppointment, { isLoading: isDeleting }] = appointmentAPI.useDeleteAppointmentMutation();

    const invalidateAppointmentTag = useCallback(() => {
        dispatch(appointmentAPI.util.invalidateTags(['Appointment']));
        dispatch(appointmentWidgetAPI.util.invalidateTags(['AppointmentWidget']));
    }, [dispatch]);

    const { isSubmitting } = useAppSelector((store) => store.submitting);
    const { isForeignAppointment } = useAppSelector((state) => state.calendar);

    const isEdit = useMemo(() => !!eventId, [eventId]);

    const handleEventCreate = (data: IAppointmentPayload) => {
        createAppointment(data)
            .unwrap()
            .then(() => {
                showSnackbar({
                    message: 'Time Block Created',
                    alertSeverity: SnackBarTypes.Success
                });
                invalidateAppointmentTag();
                onClose();
                dispatch(stopSubmitting());
            })
            .catch((e) => {
                if (e.data) {
                    showSnackbar({
                        message: e.data,
                        alertSeverity: SnackBarTypes.Error
                    });
                } else {
                    showSnackbar({
                        message:
                            data.type === AppointmentType.Appointment
                                ? "Appointment hasn't been created"
                                : "Time Block hasn't been Created",
                        alertSeverity: SnackBarTypes.Error
                    });
                }
                dispatch(stopSubmitting());
            });
    };

    const handleUpdateEvent = (id: string, data: IAppointmentPayload) => {
        updateAppointment({ appointmentId: id, data })
            .unwrap()
            .then(() => {
                showSnackbar({
                    message: 'Time Block Updated',
                    alertSeverity: SnackBarTypes.Success
                });
                invalidateAppointmentTag();
                onClose();
                dispatch(stopSubmitting());
            })
            .catch((e) => {
                if (e.data) {
                    showSnackbar({
                        message: e.data,
                        alertSeverity: SnackBarTypes.Error
                    });
                } else {
                    showSnackbar({
                        message: `Time Block hasn't been Updated`,
                        alertSeverity: SnackBarTypes.Error
                    });
                }
                dispatch(stopSubmitting());
            });
    };

    const handleEventDelete = useCallback(
        (id: string | number) => {
            deleteAppointment(id)
                .unwrap()
                .then(() => {
                    showSnackbar({
                        message: 'Time Block Deleted',
                        alertSeverity: SnackBarTypes.Success
                    });
                    onClose();
                    invalidateAppointmentTag();
                })
                .catch(() => {
                    showSnackbar({
                        message: `Time Block hasn't been Deleted`,
                        alertSeverity: SnackBarTypes.Error
                    });
                });
        },
        [deleteAppointment, invalidateAppointmentTag, onClose, showSnackbar]
    );

    const deleteEventConfirm = useCallback(
        (id: string | number) => {
            dispatch(
                openConfirmPopup({
                    onConfirm: () => handleEventDelete(id),
                    confirmText: `Delete`,
                    text: `Are you sure you want to delete this time block?`
                })
            );
        },
        [dispatch, handleEventDelete]
    );

    const handleSubmit = (formData: BlockTimeFormType) => {
        const sendData = (data: IAppointmentPayload) => {
            dispatch(startSubmitting());
            if (block && eventId) {
                handleUpdateEvent(String(block.id), data);
            } else {
                handleEventCreate(data);
            }
        };

        if (formData.employee && formData.start && formData.end) {
            const data = ({
                employee_id: formData.employee.id,
                start_at: formData.start.toISOString(true),
                end_at: formData.end.toISOString(true),
                note: formData.title,
                location_id: location.id,
                type: AppointmentType.Blocked_Time
            } as unknown) as IAppointmentPayload;
            // check pasttime
            if (!isEventDateValid(data, location.time_zone)) {
                dispatch(
                    openConfirmPopup({
                        onConfirm: () => {
                            sendData(data);
                        },
                        onClose: () => {
                            dispatch(stopSubmitting());
                        },
                        confirmText: `${block ? 'Update' : 'Create'}`,
                        text: `Are you sure you want to ${block ? 'update' : 'create'} Time Block for the past date?`
                    })
                );
            } else {
                sendData(data);
            }
        }
    };

    const modalActions = useMemo(
        () =>
            !isLoadingBlock && eventId ? (
                <IconButton
                    disabled={isSubmitting}
                    onClick={() => deleteEventConfirm(eventId)}
                    color="error"
                    sx={{ border: '1px solid currentColor', borderRadius: 1 }}
                >
                    <DeleteIcon />
                </IconButton>
            ) : undefined,
        [isLoadingBlock, eventId, isSubmitting, deleteEventConfirm]
    );

    const okButtonText = useMemo(() => (isEdit ? 'Save' : 'Create'), [isEdit]);

    const employeeId = user && user.employee.role.name === UserRole.Provider ? user.employee.id : selectedEmployeeId;

    const initialValues = useMemo<BlockTimeFormType>(() => {
        if (eventId) {
            return {
                title: block?.note || '',
                employee: block?.employee || null,
                start: moment(block?.start_at),
                end: moment(block?.end_at)
            };
        }

        return {
            title: '',
            employee: employees?.find((staff) => staff.id === employeeId) || null,
            start: moment(range?.start || null, apiTimeFormat),
            end: moment(range?.end || null, apiTimeFormat)
        };
    }, [block, employees, eventId, range, employeeId]);

    return (
        <EntityDrawerContainer>
            <EntityDrawerHeader title="Block Time" onClose={onClose} />
            <EntityDrawerContent>
                <Box p={2}>
                    {!isLoadingBlock && !isFetching ? (
                        <BlockTimeForm
                            userRole={user?.employee.role.name}
                            employees={employees}
                            initialValues={initialValues}
                            onSubmit={handleSubmit}
                            isBlocked={isCreating || isUpdating || isDeleting}
                        />
                    ) : null}
                    {eventId ? <BlockTimeHistory eventId={eventId} /> : null}
                    {eventId && (isLoadingBlock || isFetching) ? <BlockSkeleton /> : null}
                </Box>
            </EntityDrawerContent>
            <EntityDrawerActions>
                <Box sx={{ mr: 'auto' }}>{modalActions}</Box>
                <UnfilledButton onClick={onClose} text="Close" size="md" />
                <FilledButton
                    startIcon={block ? undefined : <AddBoxOutlined />}
                    type="submit"
                    size="md"
                    form="block_time_form"
                    text={okButtonText}
                    disabled={isSubmitting || isForeignAppointment}
                />
            </EntityDrawerActions>
        </EntityDrawerContainer>
    );
};

export default BlockTimeDialog;
