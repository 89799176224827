import { Box } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { ForceMobileLayoutProvider } from '../force-mobile-layout-context';

const EntityDrawerActions: FC<PropsWithChildren<unknown>> = ({ children }) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            py: 1,
            px: 2,
            borderTop: '1px solid',
            borderTopColor: 'grey.200',
            gap: '8px',
            flexGrow: 0,
            flexShrink: 0,
            justifyContent: 'flex-end'
        }}
    >
        <ForceMobileLayoutProvider value>{children}</ForceMobileLayoutProvider>
    </Box>
);

export default EntityDrawerActions;
