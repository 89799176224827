import { IconButton } from '@material-ui/core';
import CloseSvg from '../wizard/svgs/CloseSvg';
import { Box } from '@mui/material';
import { FC } from 'react';

type EntityDrawerHeaderProps = {
    title: string;
    onClose?: () => void;
    color?: string | null;
};

const EntityDrawerHeader: FC<EntityDrawerHeaderProps> = ({ title, onClose, color }) => (
    <Box
        sx={{
            position: 'relative',
            flexGrow: 0,
            flexShrink: 1,
            fontSize: '18px',
            fontWeight: '700',
            lineHeight: '1.3rem',
            textAlign: 'center',
            px: 1,
            py: 2,
            backgroundColor: color ?? 'primary.main',
            color: '#fff'
        }}
    >
        {title}
        {onClose && (
            <IconButton
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: '20px',
                    top: '14px',
                    cursor: 'pointer',
                    padding: '0',
                    '&:hover': {
                        background: 'transparent'
                    }
                }}
            >
                <CloseSvg />
            </IconButton>
        )}
    </Box>
);

export default EntityDrawerHeader;
