import AppointmentDetailsDialog from '../appointmant-details-dialog/AppointmentDetailsDialog';
import CustomerInfoDialog from '../../views/customer/customer-info/CustomerInfoModal';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { closeDialog, DialogTypes } from '../../store/slices/entityDialogsSlice';
import { useCallback, useEffect, useMemo } from 'react';
import { Box, Drawer } from '@mui/material';
import BlockTimeDialog from '../../views/calendar/appointment-block/BlockTimeDialog';
import { Theme, useMediaQuery } from '@material-ui/core';
import {
    setAppointmentDetailsFormId,
    setIsForeignAppointment,
    setSelectedEvent as setStoreEvent,
    setSelectedRange
} from '../../store/slices/calendarSlice';
import InviteCreationDialog from '../../views/calendar/invite-creation-dialog';
import NewAppointmentModal from '../../views/calendar/new-appointment-modal';
import moment from 'moment-timezone';
import { clearOuterAppointment } from '../../store/slices/outerAppointmentSlice';
import { useLocation } from 'react-router';
import { openConfirmPopup } from '../../store/confirmPopupSlice';
import { useBlocker } from 'react-router-dom';
import { setSelectedCustomerId } from '../../store/slices/calendarFilterSlice';
import CashLedger from '../cash-ledger';
import useHeaderHeight from '../../hooks/use-header-height';

const EntityDialogs = () => {
    const height = useHeaderHeight();
    const location = useLocation();
    const isDesktop = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.up('lg'));
    const isMobile = useMediaQuery('(max-width:768px)');

    const { entityId, entityType } = useAppSelector((state) => state.entityDialogs);
    const { selectedLocation, cellData, selectedCustomerId } = useAppSelector((state) => state.calendarFilter);
    const { selectedRange, selectedEmployeeId, shouldSubmitFormOnTabChange } = useAppSelector((state) => state.calendar);

    const inviteDuration = useMemo(() => {
        const calendarMinDuration = cellData.duration === '01:00:00' ? 60 : 30;
        return calendarMinDuration === selectedRange?.duration ? undefined : selectedRange?.duration;
    }, [cellData.duration, selectedRange]);

    const newAppointmentSelectedRangeEnd = useMemo(() => {
        const calendarMinDuration = cellData.duration === '01:00:00' ? 60 : 30;
        if (selectedRange) {
            const start = moment(selectedRange.start);
            const end = moment(selectedRange.end);
            const diff = end.diff(start, 'minutes');

            return diff !== calendarMinDuration ? selectedRange.end : undefined;
        }

        return undefined;
    }, [cellData.duration, selectedRange]);

    const dispatch = useAppDispatch();

    const handleClose = useCallback(() => {
        dispatch(closeDialog());

        // From old calendar action
        dispatch(clearOuterAppointment());
        dispatch(setIsForeignAppointment(false));
        dispatch(setSelectedRange(null));

        // Clearing appointment edit state
        dispatch(clearOuterAppointment());
        dispatch(setStoreEvent(null));

        // Clear details form id
        dispatch(setAppointmentDetailsFormId(undefined));

        // Clear selected customer id
        dispatch(setSelectedCustomerId(null));
    }, [dispatch]);

    const open = useMemo(() => !!entityType, [entityType]);

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) =>
            currentLocation.pathname !== nextLocation.pathname && shouldSubmitFormOnTabChange && !!entityId && entityType === 'appointment'
    );

    const sidebarData = useMemo(() => {
        const showAsNormalDrawer = entityType === DialogTypes.Ledger || !isDesktop;
        const offset = !showAsNormalDrawer ? height : '0px';
        const variant: 'temporary' | 'persistent' = showAsNormalDrawer ? 'temporary' : 'persistent';
        return {
            variant,
            height: `calc(100vh - ${offset})`,
            offsetTop: offset
        };
    }, [entityType, height, isDesktop]);

    useEffect(() => {
        if (blocker.state === 'blocked') {
            dispatch(
                openConfirmPopup({
                    text: 'Discard unsaved changes?',
                    confirmText: 'Discard',
                    onClose: () => {
                        blocker.reset();
                    },
                    onConfirm: () => {
                        blocker.proceed();
                        dispatch(closeDialog());
                    }
                })
            );
        }
    }, [blocker, dispatch]);

    useEffect(() => {
        if (entityType) {
            dispatch(closeDialog());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    return (
        <Box sx={{ width: 0, flexShrink: 0, '&:not(:empty)': { width: open ? '400px' : 0 } }}>
            <Drawer
                anchor="right"
                className="entityDetailsDrawer"
                variant={sidebarData.variant}
                open={open}
                ModalProps={{
                    keepMounted: false
                }}
                sx={{
                    height: sidebarData.height,
                    '& .MuiDrawer-paper': {
                        top: sidebarData.offsetTop,
                        width: '400px',
                        maxWidth: '100vw',
                        height: sidebarData.height
                    }
                }}
            >
                <Box
                    sx={{
                        height: '100%',
                        '& .MuiModal-root': { position: 'static' },
                        '& .MuiBackdrop-root': { display: 'none' },
                        '& .MuiDialog-paper': {
                            margin: 0,
                            borderRadius: '0 !important',
                            boxShadow: 'none',
                            width: '100%'
                        },
                        '& .MuiDialog-paper.MuiDialog-paperScrollPaper': {
                            height: sidebarData.height
                        }
                    }}
                >
                    {/* Appointment Details */}
                    <AppointmentDetailsDialog id={entityType === 'appointment' && entityId ? entityId : null} onClose={handleClose} />

                    {/* New Appointment */}
                    {selectedLocation && entityType === 'appointment' && !entityId ? (
                        <NewAppointmentModal
                            location={selectedLocation}
                            employeeId={selectedEmployeeId}
                            onClose={handleClose}
                            startDate={selectedRange?.start}
                            endDate={!isMobile ? newAppointmentSelectedRangeEnd : undefined}
                            customerId={selectedCustomerId}
                        />
                    ) : null}

                    {/* Appointment Invite */}
                    {entityType === 'invite' && !entityId ? (
                        <InviteCreationDialog
                            onClose={handleClose}
                            duration={inviteDuration || 0}
                            employeeId={selectedEmployeeId || 0}
                            locationId={selectedLocation?.id || 0}
                        />
                    ) : null}

                    {/* Time Block */}
                    {selectedLocation && entityType === 'block_time' ? (
                        <BlockTimeDialog
                            onClose={handleClose}
                            eventId={entityId && entityType === 'block_time' ? entityId : undefined}
                            range={selectedRange}
                            location={selectedLocation}
                            selectedEmployeeId={selectedEmployeeId}
                        />
                    ) : null}

                    {/* Customer Info */}
                    {entityType === 'customer' && !!entityId && <CustomerInfoDialog customerId={String(entityId)} onClose={handleClose} />}

                    {/* Cash Ledger */}
                    {entityType === DialogTypes.Ledger && <CashLedger onClose={handleClose} />}
                </Box>
            </Drawer>
        </Box>
    );
};

export default EntityDialogs;
