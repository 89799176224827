import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import CustomerSelect from '../../../ui-component/appointment-form/elements/customer-select';
import useDialogFunctions from '../../../hooks/useDialogFunctions';
import { ICustomer, ICustomerPayload } from '../../../models/ICustomer';
import ProviderSelect from '../../../ui-component/appointment-form/elements/ProviderSelect';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { IEmployee } from '../../../models/IEmployee';
import { Box, FormHelperText, Grid, TextField } from '@mui/material';
import { InputAdornment } from '@material-ui/core';
import NotificationsSwitch from '../../../ui-component/appointment-form/elements/NotificationsSwitch';
import Attachments from '../../../ui-component/appointment-form/elements/Attachments';
import useAttachmentFunctions from '../../../hooks/useAttachmentFunctions';
import * as Yup from 'yup';
import appointmentAPI from '../../../services/AppointmentService';
import { uploadImagesAndSubmit } from '../../../utils/functions/uploading-images-helpers';
import { IAppointmentInvitePayload } from '../../../models/IAppointment';
import { startSubmitting, stopSubmitting } from '../../../store/slices/SubmittingSlice';
import useShowSnackbar from '../../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../../store/snackbarReducer';
import AppointmentServicesSubform, {
    AppointmentServiceRowType
} from '../../../ui-component/appointment-form/elements/appointment-services-subform';
import servicesSchema from '../../../ui-component/form/schemes/services-array-schema';
import { getMaterialsBasedServicePrice, getServiceArrayDuration } from '../../../utils/services';
import { IService } from '../../../models/IService';
import useAuth from '../../../hooks/useAuth';
import useDuplicateCustomerConfirm from '../../../hooks/use-duplicate-customer-confirm';
import ExistingCustomerAlert from '../../../ui-component/ExistingCustomerAlert';
import useCreateCustomer from '../../../hooks/use-create-customer';
import AppointmentNotes from '../../../ui-component/appointment-form/elements/AppointmentNotes';
import useExtendedFormik from '../../../hooks/useExtendedFormik';
import EntityDrawerContainer from '../../../ui-component/entity-drawer-layout/EntityDrawerContainer';
import EntityDrawerHeader from '../../../ui-component/entity-drawer-layout/EntityDrawerHeader';
import EntityDrawerContent from '../../../ui-component/entity-drawer-layout/EntityDrawerContent';
import EntityDrawerActions from '../../../ui-component/entity-drawer-layout/EntityDrawerActions';
import UnfilledButton from '../../../ui-component/form/buttons/UnfilledButton';
import FilledButton from '../../../ui-component/form/buttons/FilledButton';

type InviteCreationDialogProps = {
    onClose: () => void;
    duration?: number | null | undefined;
    locationId: number;
    employeeId: number;
};

type CreateInviteFormType = {
    employee?: IEmployee | null;
    location_id: number;
    services: AppointmentServiceRowType[];
    customer: ICustomer | null;
    note: string | null;
    private_note: string | null;
    duration: number | null;
    is_notifications_enabled: boolean | null;
};

const InviteCreationDialog: FC<InviteCreationDialogProps> = ({ onClose, duration, locationId, employeeId }) => {
    const dispatch = useAppDispatch();
    const { user } = useAuth();
    const [pendingData, setPendingData] = useState(false);
    const { showSnackbar } = useShowSnackbar();
    const { allEmployees } = useAppSelector((state) => state.calendarFilter);
    const submitBtnRef = useRef<HTMLButtonElement>(null);

    const {
        attachments,
        setAttachments,
        attachmentsIdsToDelete,
        setAttachmentsIdsToDelete,
        attachmentError,
        setAttachmentError
    } = useAttachmentFunctions([]);

    const [sendInvite] = appointmentAPI.useSendInviteToScheduleMutation();
    const { isDialogOpen: isNewCustomerOpen, openDialog: openNewCustomer, closeDialog: closeNewCustomer } = useDialogFunctions();
    const isMultiServicesEnabled = !!user?.currentCompany.settings?.widget?.use_multiservices;

    const { data: existingCustomerData, handleCustomerError, existingCustomer, clearError } = useDuplicateCustomerConfirm();

    const handleCloseNewCustomer = useCallback(() => {
        clearError();
        closeNewCustomer();
        setPendingData(false);
    }, [clearError, closeNewCustomer]);

    const validationSchema = Yup.object().shape({
        employee: Yup.object().typeError('Provider is required').required('Provider is required'),
        location_id: Yup.number().required(),
        service: Yup.object({ id: Yup.number().positive() }).typeError('Service is required').required('Service is required'),
        duration: Yup.number().min(10).max(600).required('Duration is required'),
        customer: isNewCustomerOpen
            ? Yup.mixed().notRequired()
            : Yup.object({ id: Yup.number().positive() }).typeError('Customer is required').required('Customer is required'),
        note: Yup.string().trim().nullable().notRequired(),
        private_note: Yup.string().trim().nullable().notRequired(),
        is_notifications_enabled: Yup.boolean().required(),
        services: Yup.array().of(servicesSchema).min(1, 'At least one service is required').required('Services is required')
    });

    const initialValues = useMemo(
        () => ({
            employee: allEmployees.find((e) => e.id === employeeId),
            location_id: locationId,
            services: [{ service: undefined, price: null, prepay: null, materials_amount: null }],
            duration: duration || null,
            customer: null,
            note: '',
            private_note: '',
            price: 0,
            prepay: null,
            is_notifications_enabled: true
        }),
        [allEmployees, duration, employeeId, locationId]
    );

    const handleImagesError = useCallback(
        (error: Error) => {
            showSnackbar({
                message: error.message ?? JSON.stringify(error),
                alertSeverity: SnackBarTypes.Error
            });
        },
        [showSnackbar]
    );

    const onError = useCallback(
        (error: { message?: string; data: string; errors: Record<string, string | string[]> }) => {
            dispatch(stopSubmitting());
            if (error.errors) {
                Object.entries(error.errors).forEach(([key, value]) => {
                    const errKey = key.replace('service_ids', 'services');
                    const errValue = typeof value === 'string' ? value : value.join(', ');

                    setFieldError(errKey, errValue);
                });
            } else {
                showSnackbar({
                    message: error.data ?? JSON.stringify(error),
                    alertSeverity: SnackBarTypes.Error
                });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, showSnackbar]
    );

    const onSubmit = useCallback(
        (data: CreateInviteFormType) => {
            setPendingData(false);
            const { employee, services, customer } = data;
            if (employee && services.length && customer) {
                dispatch(startSubmitting());
                setAttachmentsIdsToDelete?.([]);
                uploadImagesAndSubmit({
                    attachments,
                    submitCb: (images) => {
                        const payload: IAppointmentInvitePayload = {
                            employee_id: employee.id,
                            location_id: data.location_id,
                            service_ids: services
                                .filter((s) => !!s.service)
                                .map((item) => ({
                                    id: item.service?.id ?? 0,
                                    price: item.price,
                                    prepay: item.prepay,
                                    materials_amount: item.service?.use_materials ? item.materials_amount : undefined
                                })),
                            customer_id: customer.id,
                            note: data.note,
                            private_note: data.private_note,
                            images,
                            duration: parseInt(String(data.duration), 10),
                            is_notifications_enabled: !!data.is_notifications_enabled
                        };

                        sendInvite(payload)
                            .unwrap()
                            .then(() => {
                                showSnackbar({
                                    alertSeverity: SnackBarTypes.Success,
                                    message: 'Invite Successfully Sent!'
                                });
                                onClose();
                            })
                            .catch((e) => onError(e))
                            .finally(() => {
                                dispatch(stopSubmitting());
                            });
                    },
                    updateAttachmentsCb: (files) => setAttachments(files),
                    imagesToDelete: attachmentsIdsToDelete,
                    uploadingErrorCb: (e) => {
                        setAttachmentsIdsToDelete?.([]);
                        handleImagesError(e);
                    },
                    deletingImagesErrorCb: (e) => handleImagesError(e)
                });
            }
        },

        [
            attachments,
            attachmentsIdsToDelete,
            dispatch,
            onClose,
            onError,
            sendInvite,
            setAttachments,
            setAttachmentsIdsToDelete,
            showSnackbar,
            handleImagesError
        ]
    );

    const handleSaveInvite = useCallback(
        (formData: CreateInviteFormType) => {
            const needSaveCustomerFirst = !formData.customer && isNewCustomerOpen;
            if (needSaveCustomerFirst) {
                setPendingData(true);
                submitBtnRef?.current?.click();
            } else {
                onSubmit(formData);
            }
        },
        [isNewCustomerOpen, onSubmit, submitBtnRef]
    );

    const {
        handleSubmit,
        values,
        setFieldValue,
        touched,
        errors,
        setFieldTouched,
        handleBlur,
        handleChange,
        setFieldError
    } = useExtendedFormik<CreateInviteFormType>({
        initialValues,
        validationSchema,
        onSubmit: handleSaveInvite,
        validateOnChange: true,
        validateOnBlur: true
    });

    const newCustomerFormContainer = useRef<any>(null);

    const handleCustomerCreationCb = useCallback(
        (customer: ICustomer) => {
            setFieldValue('customer', customer);
            handleCloseNewCustomer();
            setFieldTouched('customer');
            if (pendingData) {
                setPendingData(false);
                onSubmit({ ...values, customer });
            } else {
                dispatch(stopSubmitting());
                showSnackbar({
                    message: 'Customer updated successfully.',
                    alertSeverity: SnackBarTypes.Success
                });
            }
        },
        [handleCloseNewCustomer, dispatch, onSubmit, pendingData, setFieldTouched, setFieldValue, showSnackbar, values]
    );

    const handleCreateCustomer = useCreateCustomer(handleCustomerCreationCb, handleCustomerError);

    const handleSaveCustomer = useCallback(
        (formData: ICustomerPayload, id?: number) => {
            handleCreateCustomer({ ...formData, id });
        },
        [handleCreateCustomer]
    );

    const handleChangeProvider = useCallback(
        (newValue: IEmployee | null) => {
            setFieldValue('employee', newValue);
            if (newValue) {
                setFieldTouched('employee', false);
            }

            const newServices = values.services.map((serviceRow) => {
                if (serviceRow.service) {
                    return {
                        ...serviceRow,
                        price: getMaterialsBasedServicePrice(serviceRow.service, newValue?.services || [], serviceRow.materials_amount)
                    };
                }

                return serviceRow;
            });
            setFieldValue('services', newServices);
        },
        [setFieldTouched, setFieldValue, values.services]
    );

    const combinedDuration = useMemo(() => {
        const servicesArray: IService[] = [];
        values.services.forEach(({ service }) => {
            if (service) servicesArray.push(service);
        });

        return getServiceArrayDuration(servicesArray, values.employee);
    }, [values.services, values.employee]);

    useEffect(() => {
        if (!duration) {
            setFieldValue('duration', combinedDuration);
        }
    }, [combinedDuration, duration, setFieldValue]);

    return (
        <EntityDrawerContainer>
            <EntityDrawerHeader onClose={onClose} title="Create Invite" />
            <EntityDrawerContent>
                <Box p={2}>
                    <Box>
                        {existingCustomerData ? (
                            <ExistingCustomerAlert email={existingCustomerData?.email} phone={existingCustomerData?.phone} />
                        ) : null}
                    </Box>

                    <div ref={newCustomerFormContainer} />
                    <Grid container component="form" id="CreateInviteForm" onSubmit={handleSubmit} noValidate spacing={1}>
                        <Grid item xs={12}>
                            <CustomerSelect
                                values={values}
                                setFieldValue={setFieldValue}
                                touched={touched}
                                errors={errors}
                                setFieldTouched={setFieldTouched}
                                handleBlur={handleBlur}
                                formContainerRef={newCustomerFormContainer}
                                isNewCustomerOpen={isNewCustomerOpen}
                                closeNewCustomer={handleCloseNewCustomer}
                                openNewCustomer={openNewCustomer}
                                onSaveCustomer={handleSaveCustomer}
                                submitBtnRef={submitBtnRef}
                                existingCustomer={existingCustomer}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <ProviderSelect
                                values={values}
                                touched={touched}
                                errors={errors}
                                handleBlur={handleBlur}
                                onChange={handleChangeProvider}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <AppointmentServicesSubform
                                value={values.services}
                                setValue={setFieldValue}
                                setTouched={setFieldTouched}
                                valuePrefix="services"
                                errors={errors.services}
                                touched={touched.services}
                                employeeServices={values.employee?.services}
                                useMultiservices={isMultiServicesEnabled}
                            />
                            {typeof errors.services === 'string' && <FormHelperText error>{errors.services}</FormHelperText>}
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label="Duration"
                                id="duration"
                                name="duration"
                                value={values?.duration ?? ''}
                                onChange={handleChange}
                                InputProps={{
                                    endAdornment: <InputAdornment position="start">Min</InputAdornment>
                                }}
                                error={Boolean(touched?.duration && errors.duration)}
                                helperText={touched?.duration ? errors?.duration : null}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Attachments
                                values={values}
                                attachments={attachments}
                                setAttachments={setAttachments}
                                attachmentsIdsToDelete={attachmentsIdsToDelete}
                                setAttachmentsIdsToDelete={setAttachmentsIdsToDelete}
                                error={attachmentError ?? ''}
                                setError={setAttachmentError}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <AppointmentNotes
                                formikInstance={{
                                    values,
                                    errors,
                                    touched,
                                    setFieldTouched,
                                    setFieldValue,
                                    handleBlur
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <NotificationsSwitch
                                value={!!values.is_notifications_enabled}
                                onChange={(v) => setFieldValue('is_notifications_enabled', v)}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </EntityDrawerContent>
            <EntityDrawerActions>
                <UnfilledButton onClick={onClose} text="Close" size="md" />
                <FilledButton type="submit" size="md" form="CreateInviteForm" text="Invite" className="DialogOkBtn" />
            </EntityDrawerActions>
        </EntityDrawerContainer>
    );
};

export default InviteCreationDialog;
