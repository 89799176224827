import { FC, ReactNode } from 'react';
import { OverrideIcon } from '../../../types';
import { NavLink } from 'react-router-dom';
import { Box, Theme, useMediaQuery } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

type SettingsMenuLinkProps = {
    title: ReactNode;
    url: string;
    Icon: OverrideIcon;
};

const SettingsMenuLink: FC<SettingsMenuLinkProps> = ({ title, url, Icon }) => {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('mobile'));
    return (
        <Box
            sx={{
                width: '100%',
                fontSize: '1rem',

                '& > .link': {
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    textDecoration: 'none',
                    lineHeight: 1.5,
                    padding: '16px 12px',
                    borderRadius: '28px',
                    color: 'grey.800',
                    transitionDuration: '.25s',

                    '@media (hover: hover)': {
                        '&:hover': {
                            color: 'secondary.main',
                            backgroundColor: 'secondary.light'
                        }
                    }
                },

                '& > .link.active': {
                    color: 'secondary.main',
                    backgroundColor: 'secondary.light'
                }
            }}
        >
            <NavLink to={url} className="link">
                {Icon && <Icon sx={{ mr: 1 }} />}
                {title}
                {isMobile && <ChevronRightIcon sx={{ color: 'grey.300', ml: 'auto' }} />}
            </NavLink>
        </Box>
    );
};
export default SettingsMenuLink;
