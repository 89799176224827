import { createTheme } from '@mui/material/styles';
import colors from 'assets/scss/_themes-vars-v5.module.scss';
import { themePalette, themeTypography, componentStyleOverrides, customShadows } from './v5';

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xs: true;
        sm: true;
        md: true;
        lg: true;
        xl: true;
        mobile: true;
    }

    interface ThemeOptions {
        customShadows: Record<string, string>;
    }

    interface PaletteOptions {
        newColors: {
            gray: {
                400?: string;
                600?: string;
            };
            black?: string;
            darkBlue?: string;
        };
        widget?: {
            bgTop?: string;
            bgBottom?: string;
            title?: string;
            text?: string;
            green?: string;
            lightGreen?: string;
            darkGreen?: string;
            buttonDetails?: string;
        };
    }
}

const themeOptions = {
    colors,
    heading: colors.grey800,
    paper: colors.paper,
    backgroundDefault: colors.paper,
    background: colors.grey200,
    textDark: colors.grey900,
    darkTextPrimary: colors.grey700,
    darkTextSecondary: colors.grey500,
    menuSelected: colors.secondaryDark,
    menuSelectedBack: colors.secondaryLight,
    divider: colors.grey200,
    customization: {
        navType: 'light' as const,
        fontFamily: 'Roboto, sans-serif'
    }
};

const theme = createTheme({
    direction: 'ltr',
    mixins: {
        toolbar: {
            minHeight: '48px',
            padding: '16px',
            '@media (min-width: 600px)': {
                minHeight: '48px'
            }
        }
    },

    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            mobile: 768,
            md: 960,
            lg: 1280,
            xl: 1920
        }
    },
    palette: themePalette(themeOptions),
    typography: themeTypography(themeOptions),
    components: componentStyleOverrides(themeOptions),
    customShadows: customShadows(themeOptions.customization.navType, themeOptions)
});

export default theme;
