import { FC, useCallback, useMemo, useState } from 'react';
import { Box, Button, ButtonProps, MenuItem, Stack, TextField, Theme, Typography, useMediaQuery } from '@mui/material';
import { ICompany } from '../../../models/ICompany';
import QRCode from 'react-qr-code';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import useShowSnackbar from '../../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../../store/snackbarReducer';

const KioskTab: FC<{ company: ICompany }> = ({ company }) => {
    const isMobile = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('mobile'));
    const isSmall = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('sm'));

    const [selectedLocationUrl, setSelectedLocationUrl] = useState('');
    const { showSnackbar } = useShowSnackbar();

    const handleCopyUrl = useCallback(() => {
        navigator.clipboard.writeText(selectedLocationUrl).then(() => {
            showSnackbar({ alertSeverity: SnackBarTypes.Success, message: 'Link copied to clipboard!' });
        });
    }, [selectedLocationUrl, showSnackbar]);

    const copyBtnProps = useMemo<ButtonProps>(
        () => ({
            onClick: handleCopyUrl,
            className: 'forcedBg',
            color: 'primary',
            size: 'large',
            sx: {
                padding: '8px 16px',
                width: '100%',
                alignItems: 'center',
                display: 'flex',
                textTransform: 'none',
                fontSize: '1rem',
                lineHeight: 1.5
            }
        }),
        [handleCopyUrl]
    );

    return (
        <Stack spacing={4} direction={isMobile ? 'column' : 'row'}>
            <Stack spacing={3} sx={{ maxWidth: isMobile ? '100%' : '480px' }}>
                <Box>
                    <Typography variant="h4" mb={0.5}>
                        Kiosk Portal
                    </Typography>
                    <Typography sx={{ opacity: 0.6, fontSize: '1rem' }}>
                        The Kiosk Portal can be used as a method for customer check-in, form completion and staff notification at the time
                        of arrival. It is recommended to utilize this on a dedicated device. A location’s portal can be opened by either
                        opening the link provided or scanning the respective QR code from the device.
                    </Typography>
                </Box>

                <Stack spacing={2} sx={{ width: isMobile ? '100%' : '343px', maxWidth: '100%' }}>
                    <TextField
                        fullWidth
                        label="Choose your location"
                        value={selectedLocationUrl}
                        onChange={(e) => setSelectedLocationUrl(e.target.value)}
                        select
                    >
                        <MenuItem value="">Choose your location</MenuItem>
                        {company.locations?.map((location) => (
                            <MenuItem key={location.id} value={location.kioskUrlShort}>
                                {location.name}
                            </MenuItem>
                        ))}
                    </TextField>

                    {selectedLocationUrl && !isMobile ? (
                        <Button {...copyBtnProps}>
                            {selectedLocationUrl}
                            <FileCopyOutlinedIcon sx={{ fontSize: '1em', ml: 1 }} />
                        </Button>
                    ) : null}
                </Stack>
            </Stack>
            {selectedLocationUrl ? (
                <Stack spacing={3} alignItems="center">
                    <Box
                        p={3}
                        sx={{
                            border: '1px solid',
                            borderRadius: '8px',
                            borderColor: isMobile ? '#d9d9d9' : 'transparent',
                            width: isSmall ? '240px' : '304px',
                            maxWidth: '100%'
                        }}
                    >
                        <QRCode value={selectedLocationUrl} size={isSmall ? 192 : undefined} />
                    </Box>
                    {isMobile && (
                        <Button {...copyBtnProps}>
                            {selectedLocationUrl}
                            <FileCopyOutlinedIcon sx={{ fontSize: '1em', ml: 1 }} />
                        </Button>
                    )}
                </Stack>
            ) : null}
        </Stack>
    );
};

export default KioskTab;
