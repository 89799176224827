import { ReactNode } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import TableLoader from './TableLoader';
import TableEmpty from './TableEmpty';
import { SxProps } from '@mui/system';

export type CbTableColumn = {
    id: string;
    label: string;
    sortable?: boolean;
};

export type CBTablePropsType<T> = {
    columns: Array<CbTableColumn>;
    rows: Array<T>;
    renderRow: (row: T, index?: number) => ReactNode;
    isLoading?: boolean;
    sortingKey?: string;
    order?: 'asc' | 'desc';
    handleSorting?: (key: string) => void;
    containerSx?: SxProps;
    emptyLabel?: ReactNode;
};

const CBTable = <K,>({
    columns,
    rows,
    renderRow,
    isLoading,
    sortingKey,
    order,
    handleSorting,
    containerSx,
    emptyLabel
}: CBTablePropsType<K>) => (
    <TableContainer className="cb-table-container" sx={containerSx}>
        <Table size="small" className="table-borderless table-striped">
            <TableHead>
                <TableRow>
                    {columns.map((column) => (
                        <TableCell key={column.label}>
                            {column.sortable ? (
                                <TableSortLabel
                                    active={sortingKey === column.id}
                                    direction={sortingKey === column.id ? order : 'asc'}
                                    onClick={handleSorting ? () => handleSorting(column.id) : undefined}
                                >
                                    {column.label}
                                </TableSortLabel>
                            ) : (
                                column.label
                            )}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {isLoading ? <TableLoader colSpan={columns.length} /> : null}
                {!isLoading && !rows.length ? <TableEmpty colSpan={columns.length} text={emptyLabel} /> : null}
                {!!rows.length && !isLoading ? rows.map(renderRow) : null}
            </TableBody>
        </Table>
    </TableContainer>
);

export default CBTable;
