import { useMemo, useState, useCallback, RefObject, useEffect } from 'react';
import Menu from './elements/Menu';
import Details from './elements/tabs-content/details';
import Activity from './elements/tabs-content/Activity';
import Messages from './elements/tabs-content/Messages';
import CustomerInfo from './elements/tabs-content/customer-info';
import Payments from './elements/tabs-content/payments';
import { Box, Alert, Link, CircularProgress, Stack } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { setShouldSubmitFormOnTabChange } from '../../../../store/slices/calendarSlice';
import { useSearchParams } from 'react-router-dom';
import AppointmentProducts from './elements/tabs-content/products/AppointmentProducts';
import { openConfirmPopup } from '../../../../store/confirmPopupSlice';

interface AppointmentDetailsProps {
    submitButtonRef?: RefObject<HTMLButtonElement>;
    isLoading?: boolean;
}

const AppointmentDetails = ({ submitButtonRef, isLoading }: AppointmentDetailsProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeTab, setActiveTab] = useState<string>('details');
    const { selectedEvent, shouldSubmitFormOnTabChange, isForeignAppointment } = useAppSelector((state) => state.calendar);
    const { selectedLocation } = useAppSelector((state) => state.calendarFilter);
    const dispatch = useAppDispatch();

    const content = useMemo(() => {
        switch (activeTab) {
            case 'activity':
                return <Activity />;
            case 'customer-info':
                return <CustomerInfo onResetTabs={() => setActiveTab('details')} />;
            case 'messages':
                return <Messages />;
            case 'products':
                return <AppointmentProducts />;
            case 'payments':
                return <Payments />;
            case 'details':
            default:
                return <Details />;
        }
    }, [activeTab]);

    const handleClickTab = useCallback(
        (newValue: string) => {
            if (shouldSubmitFormOnTabChange && !isForeignAppointment) {
                dispatch(
                    openConfirmPopup({
                        text: 'Save changes before switching tab?',
                        confirmText: 'Save Changes',
                        cancelText: 'Discard Changes',
                        onConfirm: () => {
                            submitButtonRef?.current?.click();
                            dispatch(setShouldSubmitFormOnTabChange(false));
                            setActiveTab(newValue);
                        },
                        onClose: () => {
                            setActiveTab(newValue);
                            dispatch(setShouldSubmitFormOnTabChange(false));
                        }
                    })
                );
            } else {
                setActiveTab(newValue);
            }
        },
        [shouldSubmitFormOnTabChange, isForeignAppointment, submitButtonRef, dispatch]
    );

    useEffect(() => {
        const tab = searchParams.get('tab');
        if (tab === 'messages') {
            setActiveTab('messages');
            setSearchParams(new URLSearchParams());
        }
    }, [searchParams, setSearchParams]);

    return (
        <Stack height="100%" alignContent="flex-start">
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '73px',
                    borderRight: `none`,
                    borderBottom: `1px solid`,
                    borderBottomColor: 'grey.300',
                    pt: '6px'
                }}
            >
                <Menu
                    activeTab={activeTab}
                    handleClickTab={handleClickTab}
                    has_waiver={selectedEvent?.has_waiver}
                    deposit_request={selectedEvent?.deposit_request}
                    disabled={isLoading}
                />
            </Box>
            <Box
                py={2}
                height="calc(100% - 73px)"
                overflow="auto"
                sx={{
                    '& ::-webkit-scrollbar': {
                        height: '5px',
                        width: '5px'
                    },

                    '& ::-webkit-scrollbar-track': {
                        borderRadius: '3px',
                        backgroundColor: 'grey.200'
                    },

                    '& ::-webkit-scrollbar-thumb': {
                        backgroundColor: 'grey.400',
                        borderRadius: '3px'
                    }
                }}
            >
                {!isLoading && !isForeignAppointment && selectedLocation && selectedEvent?.location.id !== selectedLocation.id ? (
                    <Box px={2} mb={1}>
                        <Alert severity="info">This appointment is for {selectedEvent?.location.name}</Alert>
                    </Box>
                ) : null}
                {!isLoading && isForeignAppointment && !!selectedEvent?.foreign_company ? (
                    <Box px={2} mb={1}>
                        <Alert severity="info">
                            This appointment is for{' '}
                            <Link href={`/calendar/organization/${selectedEvent?.foreign_company.id}/appointment/${selectedEvent?.id}/`}>
                                <>{selectedEvent?.foreign_company.name}</>
                            </Link>
                        </Alert>
                    </Box>
                ) : null}
                {!isLoading ? (
                    content
                ) : (
                    <Box sx={{ p: 3, height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                )}
            </Box>
        </Stack>
    );
};

export default AppointmentDetails;
